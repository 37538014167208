<template>
    <div class="jzProfileManage">
        <div style="width: 100%; height: 0.5px"></div>
        <div class="notice2">请退出网站管理状态,再进入该页面。</div>
        <div class="member_basic_info_area g_main_bgColor_v3">
            <div class="member_basic_info_v3">
                <div class="member_msg_head_pic_v3 icon-pnameIcon">
                    <img id="memberHeadPicV3" :src="demoHeadImg" class="memberHeadPicV3" />
                </div>
                <div class="member_name_and_level" :class="{ not_auth: !memberLevelAuthInfo.allow }">
                    <span class="memberName">123</span>
                    <span v-if="memberLevelAuthInfo.allow" class="memberLevelName">[ {{ memberLvName }} ]</span>
                </div>
            </div>
        </div>
        <ul class="modifyArea">
            <li v-if="integralOpen" id="integral">
                <div class="itemName">
                    <span class="icon-integralIcon"></span>
                    {{ LS.integral_mine + itgName }}
                </div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <li v-if="isOpenKnowPayment" id="knowPayment">
                <div class="itemName"
                    ><svg><use xlink:href="#jzm-vicon_mall" /></svg><span v-text="LS.minePayKnowProducts"></span
                ></div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <li v-if="openCollection" id="collection">
                <div class="itemName"><span class="newProfileIcon icon-starIcon"></span>{{ LS.my_collection }}</div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <li v-if="isOpenMyForm" id="myForm">
                <div class="itemName"
                    ><svg><use xlink:href="#jzm-v27" /></svg><span v-text="LS.mobi_member_myForm"></span
                ></div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <li v-if="memberBulletinOpen" id="latestNews" orderlist="1">
                <div class="itemName">
                    <span class="icon-noticeIcon"></span><span v-text="LS.memberBulletinTitle"></span>
                    <div id="bulletinTitle" sessionmid="2" class="bulletinTitle"></div>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="isCn && hasPrize && isOpenLottery" id="myreward">
                <div class="itemName"><span class="icon-pInfoIcon"></span>{{ LS.mobi_member_MyReward }}</div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <li id="personalInfo">
                <div class="itemName"><span class="icon-pInfoIcon"></span>{{ LS.mobi_membert_PersonalInfo }}</div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <li id="password">
                <div class="itemName"><span class="icon-cPswIcon"></span>{{ LS.mobi_membert_ResetPassword }}</div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="isOtherLogin" id="changeOtherLoginAcct">
                <div class="itemName"
                    ><span class="icon-cPswIcon"></span>{{ LS.mobi_membert_ChangeOtherLoginAcct }}</div
                >
                <div class="clickIcon">
                    <span class="icon-bangding"></span>
                </div>
            </li>
            <li id="memberProtocol">
                <div class="itemName">
                    <svg><use xlink:href="#agreement" /></svg>
                    <span v-text="LS.websiteAgreement || '网站协议'"></span>
                </div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <li v-if="clientDelMember" id="clientDelMember">
                <div class="itemName"
                    ><svg><use xlink:href="#logoff" /></svg><span v-text="LS.clientDel || '注销账号'"></span
                ></div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
        </ul>
        <div class="onLogout">
            <span></span>
            {{ LS.mobi_member_onLogout }}
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'JZProfileManage',
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapState('app', ['LS', 'lcid']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        memberLvName() {
            return this.renderOptions.memberLvName || '';
        },
        integralOpen() {
            return this.renderOptions.integralOpen || false;
        },
        isOpenKnowPayment() {
            return this.renderOptions.isOpenKnowPayment || false;
        },
        openCollection() {
            return this.renderOptions.openCollection || false;
        },
        isOpenMyForm() {
            return this.renderOptions.isOpenMyForm || false;
        },
        hasPrize() {
            return this.renderOptions.hasPrize || false;
        },
        isOpenLottery() {
            return this.renderOptions.isOpenLottery || false;
        },
        isOtherLogin() {
            return this.renderOptions.isOtherLogin || '';
        },
        siteResRoot() {
            return this.renderOptions.siteResRoot || '';
        },
        memberBulletinOpen() {
            return this.renderOptions.memberBulletinOpen || false;
        },
        itgName() {
            return this.renderOptions.itgName || '';
        },
        isCn() {
            return this.lcid == '2052';
        },
        demoHeadImg() {
            return `${this.siteResRoot}/image/tx1.png#default`;
        },
        memberLevelAuthInfo() {
            return this.renderOptions.memberLevelAuthInfo ?? {};
        },
        clientDelMember() {
            return this.renderOptions.clientDelMember;
        },
    },
};
</script>
