<template>
    <module-frame :module-id="moduleId">
        <div id="signupPage">
            <div class="loginHeader webHeaderBg profileLoginHeader">
                <a class="g_close icon-gClose" @click="closeClick"></a>
                <span class="title pageTitle" v-text="LS.col_member_signup"></span>
            </div>
            <div class="signUpContent">
                <div v-if="manageMode" class="notice">您目前处于网站管理状态，请先退出再注册</div>
                <div id="profileArea" class="profileArea">
                    <template v-if="signUpbyMobile">
                        <div class="register_line" :class="commonClassList">
                            <input
                                id="mobile"
                                type="text"
                                maxlength="50"
                                :name="mobileItem.name"
                                style="width: 70%"
                                class="addCtTel mustItem itemEdit userAddItem isCheckUAI g_input"
                                :placeholder="mobileItem.propPlaceholder + LS.mobi_member_Must"
                            />
                            <div class="mobileCtIcon otherSettingIcon selectAnimationRemove">
                                <div class="icon-downward"></div>
                            </div>
                            <div class="g_input selectCover selectCoverRight">+86</div>
                            <select
                                :id="MOBILE_CT"
                                :name="MOBILE_CT"
                                class="itemEdit userAddItem g_select"
                                @click="mobileCtClick"
                                @blur="mobileCtBlur"
                                @change="mobileCtChange"
                            >
                                <option
                                    v-for="ct in mobileCtLst"
                                    :key="ct.lskey"
                                    :value="ct.ctName"
                                    v-text="ct.ctShowName + ct.ctCode"
                                ></option>
                            </select>
                            <span :class="mobileItem.itemIcon"></span>
                            <span :class="mobileItem.customPropName">手机</span>
                        </div>
                        <div class="g_globalLine">
                            <span class="phone_tip" v-text="LS.memberSignup_captchaV3"></span>
                            <input
                                id="messageAuthCode"
                                type="text"
                                class="captchaText g_input"
                                maxlength="6"
                                :placeholder="messageAuthCodePlaceholder"
                            />
                            <div
                                id="messageAuthCodeClick"
                                class="fk-sign-getMobileCode fk-sign-getMobileCodeFix"
                                :title="messageAuthCodeTitle"
                                @click="signMobileCodeClick"
                                v-text="LS.mobi_getMobileCode"
                            ></div>
                            <div style="clear: both"></div>
                        </div>
                    </template>
                    <template v-else-if="signUpbyMail">
                        <div :class="commonClassList">
                            <input
                                id="email"
                                type="text"
                                maxlength="50"
                                class="mustItem itemEdit userAddItem isCheckUAI g_input"
                                :name="mailItem.name"
                                :placeholder="mailItem.propPlaceholder + LS.mobi_member_Must"
                            />
                            <span class="icon-emailIcon g_mainColor"></span>
                        </div>
                    </template>
                    <template v-else>
                        <div :class="commonClassList">
                            <input
                                id="memberSignupAcct"
                                type="text"
                                class="mustItem itemEdit userAddItem g_input"
                                :placeholder="memberSignupAcctPlaceholder"
                            />
                            <span class="icon-userIcon g_mainColor"></span><span class="mustIcon">*</span>
                        </div>
                    </template>
                    <div :class="commonClassList">
                        <input
                            id="memberSignupPwd"
                            type="password"
                            onpaste="return false;"
                            autocomplete="off"
                            class="mustItem itemEdit icon-pswIcon userAddItem g_input"
                            :placeholder="memberSignUpPwdPlaceholder"
                        />
                        <span class="icon-pswIcon g_mainColor"></span><span class="mustIcon">*</span>
                    </div>
                    <div :class="commonClassList">
                        <input
                            id="memberSignupRepwd"
                            type="password"
                            onpaste="return false;"
                            autocomplete="off"
                            class="mustItem itemEdit icon-pswIcon userAddItem g_input"
                            :placeholder="memberSingupRepwdPlaceholder"
                        />
                        <span class="icon-pswIcon g_mainColor"></span><span class="mustIcon">*</span>
                    </div>

                    <template v-for="item in filterList">
                        <template v-if="item.fieldKey == MOBILE">
                            <div :key="item.id" :class="commonClassList" class="register_line">
                                <input
                                    :id="item.fieldKey"
                                    :name="item.name"
                                    :placeholder="addMustTip(item)"
                                    type="text"
                                    maxlength="50"
                                    style="width: 65%"
                                    class="addCtTel itemEdit userAddItem g_input"
                                    :class="{
                                        mustItem: item.must == 'isCheck',
                                        isCheckUAI: item.must == 'isCheck',
                                    }"
                                />
                                <div class="mobileCtIcon otherSettingIcon selectAnimationRemove">
                                    <div class="icon-downward"></div>
                                </div>
                                <div class="g_input selectCover selectCoverRight">+86</div>
                                <select
                                    :id="MOBILE_CT"
                                    :name="MOBILE_CT"
                                    class="itemEdit userAddItem g_select"
                                    @click="mobileCtClick"
                                    @blur="mobileCtBlur"
                                    @change="mobileCtChange"
                                >
                                    <option
                                        v-for="ct in mobileCtLst"
                                        :key="ct.lskey"
                                        :value="ct.ctName"
                                        v-text="ct.ctShowName + ct.ctCode"
                                    ></option>
                                </select>
                                <span :class="item.itemIcon"></span>
                                <span :class="item.customPropName">手机</span>
                            </div>
                        </template>
                        <template v-else>
                            <div :key="item.id" :class="[commonClassList, fileClass(item)]">
                                <template v-if="item.pt == 0">
                                    <input
                                        :id="item.fieldKey"
                                        :name="item.name"
                                        :placeholder="addMustTip(item)"
                                        class="itemEdit userAddItem g_input"
                                        :class="{
                                            mustItem: item.must == 'isCheck',
                                            isCheckUAI: item.must == 'isCheck',
                                        }"
                                        type="text"
                                        maxlength="50"
                                    />
                                    <span :class="item.itemIcon"></span>
                                </template>
                                <template v-else-if="item.pt == 1">
                                    <div class="mobileCtIcon otherSettingIcon selectAnimationRemove">
                                        <div class="icon-downward"></div>
                                    </div>
                                    <input class="g_input selectCover" />
                                    <select
                                        :id="item.fieldKey"
                                        :name="item.name"
                                        :class="{
                                            isCheckUAI: item.must == 'isCheck',
                                        }"
                                        class="itemEdit userAddItem g_select"
                                        @click="itemEditClick"
                                        @blur="itemEditBlur"
                                    >
                                        <option value="" v-text="addMustTip(item)"></option>
                                        <option
                                            v-for="prop in item.propOptions"
                                            :key="prop"
                                            :value="prop"
                                            v-text="prop"
                                        ></option>
                                    </select>
                                    <span :class="item.itemIcon"></span>
                                </template>
                                <template v-else-if="item.pt == 2">
                                    <div class="member_file_tips">{{ getFileTips(item) }}</div>
                                    <div :id="'member_file_contentwrap-' + item.id" class="member_file_contentwrap">
                                        <input
                                            :id="item.fieldKey"
                                            :name="item.name"
                                            class="itemEdit userAddItem member_file_input"
                                            :class="{
                                                isCheckUAI: item.must == 'isCheck',
                                            }"
                                            :data-name="item.id"
                                        />
                                        <div class="member_file_btn">
                                            <div class="member_file_btnupload uploadify-button"></div>
                                            <span v-text="addFileText"></span>
                                        </div>
                                        <div class="member_file_content">
                                            <div class="member_file_meta">
                                                <div class="member_file_name"></div>
                                                <div class="member_file_size"></div>
                                            </div>
                                            <div class="member_file_icon">
                                                <svg class="member_file_close">
                                                    <use xlink:href="#jzm-v25"></use>
                                                </svg>
                                                <a
                                                    ref="nofllow"
                                                    class="member_file_preview_link"
                                                    href="javascript:void(0)"
                                                    target="_blank"
                                                >
                                                    <svg class="member_file_preview">
                                                        <use xlink:href="#jzm-vicon_preview"></use>
                                                    </svg>
                                                </a>
                                                <a
                                                    ref="nofllow"
                                                    class="member_file_download_link"
                                                    href="javascript:void(0)"
                                                    target="_blank"
                                                >
                                                    <svg class="member_file_download">
                                                        <use xlink:href="#jzm-v16"></use>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <span
                                    v-if="item.customPropName != ''"
                                    :class="item.customPropName"
                                    v-text="item.name"
                                ></span>
                            </div>
                        </template>
                    </template>
                    <div v-if="noRemark" :class="commonClassList">
                        <input
                            id="memberSignupRemark"
                            type="text"
                            class="itemEdit g_input userAddItem"
                            :placeholder="memberSignupRemarkPlaceholder"
                            :maxlength="memberSignupRemarkMaxLength"
                        />
                        <span class="icon-msgIcon g_mainColor" v-text="LS.mobi_memberSignup_remark"></span>
                    </div>
                    <div v-if="protocolOpen" class="ProtocolLineCtrl g_globalLine">
                        <input
                            id="memberAgreePro"
                            v-model="protocolChecked"
                            style="position: relative"
                            type="checkbox"
                            class="memberAgreeProCheck"
                        />
                        <span
                            :class="{
                                'icon-checkIcon': protocolChecked,
                                g_main_color_v3: protocolChecked,
                                'icon-uncheckIcon': !protocolChecked,
                            }"
                            @click.stop="checkLabel"
                        ></span>
                        <label class="memberAgreeProLabel" @click.stop="checkLabel">
                            <span @click.stop="checkLabel">{{ LS.mobi_member_Protocol_Msg }}</span>
                            <a
                                id="signUpMProtocol"
                                hidefocus="true"
                                class="memberProtocol g_main_color_v3"
                                v-text="signUpMProtocolName"
                            ></a>
                        </label>
                    </div>
                    <div class="signupSubmitCtrl g_globalLine">
                        <input
                            type="button"
                            class="g_button sendIcon signupSubmit submitIcon g_main_bgColor_v3 fpw_btn"
                            disabled
                            :value="LS.memberRegV3"
                            @click="memberSignupSubmitData"
                        />
                    </div>
                </div>
                <div id="checkEmail" class="checkEmail"></div>
                <a id="emailAddr" class="emailAddr" style="display: none" target="_blank">地址</a>
            </div>
        </div>
        <div id="mProPage" class="J_cusBack">
            <div class="loginHeader webHeaderBg profileLoginHeader J_registerHeader">
                <a class="g_close icon-gClose" @click="mProPageClick"></a>
                <span class="title pageTitle"></span>
            </div>
            <div class="mProContent">
                <div class="set-line" style="overflow-x: auto"></div>
            </div>
        </div>
    </module-frame>
</template>
<script>
import { mapState } from 'vuex';

import { checkBit } from '@/shared/fai';
import ModuleFrame from '@/modules/frame/index.vue';
import {
    selectAnimationCtrl,
    selectAnimation,
    signUpPersInfoChangeMobileCt,
    checkMustItem,
    initSignUpPage,
    changeSelectDefaultColor,
    selectValueChange,
    memberSignupSubmit,
    getSignMobileCode,
} from '../../method';
import { hidePlaceholder } from '@/shared/misc/placeholder';
import { changeHref } from '@/features/router/universal';
import { MemberPropDef, MemberDef } from '../../memberDef';
import { memberFileUpload } from '@/system/member/fileUpload';
export default {
    name: 'SignUpV3',
    components: {
        ModuleFrame,
    },
    props: {
        module: {
            type: Object,
            default: () => ({}),
        },
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
            MOBILE: 'mobile',
            EMAIL: 'email',
            MOBILE_CT: 'mobileCt',
            REQUIRED: 0x2,
            protocolChecked: false,
        };
    },
    computed: {
        ...mapState('app', ['LS']),
        renderOption() {
            return this.module.renderOptions;
        },
        memberSignupRemarkMaxLength() {
            return MemberDef.Limit.REMARK_MAXLEN;
        },
        signUpbyMobile() {
            return this.renderOption.signUpbyMobile || false;
        },
        signUpbyMail() {
            return this.renderOption.signUpbyMail || false;
        },
        protocolOpen() {
            return this.renderOption.proOpen || false;
        },
        noRemark() {
            return this.renderOption.noRemark || '';
        },
        proName() {
            return this.renderOption.proName || '';
        },
        mobileCtLst() {
            return this.renderOption.mobileCtNameList || [];
        },
        returnA() {
            return this.renderOption.returnA || '/index.jsp';
        },
        returnUrl() {
            return this.renderOption.returnUrl || '/index.jsp';
        },
        tagWidthClass() {
            return this.renderOption.tagWidthClass || '';
        },
        pleaseInputStr() {
            return this.LS.mobi_member_PleaseInput || '';
        },
        displayList() {
            let list = this.renderOption.displayList || [];
            // 处理下display的数据
            list.forEach((item) => {
                let { flag, pp, fieldKey, pc = '' } = item;
                let must = checkBit(flag, this.REQUIRED) ? 'isCheck' : 'notCheck';
                let propPlaceholder = pp;
                let itemIcon = '';
                let customPropName = '';
                if (fieldKey == 'email') {
                    itemIcon = 'icon-emailIcon g_mainColor';
                } else if (fieldKey == 'phone') {
                    itemIcon = 'icon-customIcon g_mainColor';
                    customPropName = 'customIcon';
                } else if (fieldKey == 'name') {
                    itemIcon = 'icon-nameIcon g_mainColor';
                } else if (fieldKey == 'mobile') {
                    itemIcon = 'icon-iphone2 g_mainColor';
                    customPropName = 'customIcon';
                } else {
                    itemIcon = 'icon-customIcon g_mainColor';
                    customPropName = 'customIcon';
                }

                let propContent = pc.split('\n');
                item.propOptions = propContent;

                item.must = must;
                item.propPlaceholder = propPlaceholder;
                item.itemIcon = itemIcon;
                item.customPropName = customPropName;
            });
            return list;
        },
        messageAuthCodePlaceholder() {
            return this.LS.mobi_inputMobileCode;
        },
        messageAuthCodeTitle() {
            return this.LS.mobi_getMobileCode;
        },
        memberSignUpPwdPlaceholder() {
            return this.pleaseInputStr + this.LS.mobi_member_Password + this.LS.mobi_member_Must;
        },
        memberSingupRepwdPlaceholder() {
            return this.pleaseInputStr + this.LS.mobi_memberSignup_retypePassword + this.LS.mobi_member_Must;
        },
        memberSignupAcctPlaceholder() {
            return this.pleaseInputStr + this.LS.mobi_member_Account + this.LS.mobi_member_Must;
        },
        memberSignupRemarkPlaceholder() {
            return this.pleaseInputStr + this.LS.mobi_memberSignup_remark;
        },
        commonClassList() {
            let classList = ['g_globalLine', this.tagWidthClass];
            return classList;
        },
        signUpMProtocolName() {
            return `《${this.proName}》`;
        },
        mobileItem() {
            let mobileList = this.displayList.filter(
                (item) => item[MemberPropDef.Info.TYPE] == MemberPropDef.Type.SYS_MOBILE
            );
            return mobileList.length ? mobileList[0] : {};
        },
        mailItem() {
            let mailList = this.displayList.filter(
                (item) => item[MemberPropDef.Info.TYPE] == MemberPropDef.Type.SYS_EMAIL
            );
            return mailList.length ? mailList[0] : {};
        },
        filterList() {
            return this.displayList.filter((item) => {
                let { fieldKey } = item;
                if (fieldKey == this.MOBILE && this.signUpbyMobile) return false;
                if (fieldKey == this.EMAIL && this.signUpbyMail) return false;
                return true;
            });
        },
        fileItemList() {
            return this.filterList.filter((item) => {
                return item.pt == 2;
            });
        },
        propParam() {
            return this.renderOption.propParam || {};
        },
        showCaptcha() {
            return this.renderOption.showCaptcha;
        },
        addFileText() {
            return `+ ${this.LS.js_add_file}`;
        },
    },
    mounted() {
        if (this.displayList.length) {
            selectValueChange('#signupPage');
            changeSelectDefaultColor('#c4c9ca', '#333333', '.jz_themeV3 #signupPage .g_select');
        }
        hidePlaceholder();
        checkMustItem('.signUpContent');
        initSignUpPage();
        this.initFileUpload();
    },
    methods: {
        initFileUpload() {
            this.fileItemList.forEach((item) => {
                memberFileUpload({
                    moduleId: this.moduleId,
                    fileSizeLimit: this.propParam[item.id].fileSizeLimit,
                    fileUpInfo: JSON.parse(item.pc).fup || {},
                    propId: item.id,
                });
            });
        },
        getFileTips(item) {
            let fileItemOption = JSON.parse(item.pc).fup || {};
            return fileItemOption.tt;
        },
        fileClass(item) {
            return item.pt == 2 ? ' memeber_file_line' : '';
        },
        closeClick() {
            let isclick = this.returnA.indexOf('onclick') != -1;
            if (isclick) {
                changeHref(document.referrer);
            } else {
                if (VITE_APP_MODE !== 'visitor') {
                    changeHref(this.returnA == '/' ? '/index.jsp' : this.returnA);
                } else {
                    changeHref(this.returnA);
                }
            }
        },
        checkLabel() {
            this.protocolChecked = !this.protocolChecked;
        },
        memberSignupSubmitData() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                memberSignupSubmit(this.returnUrl, this.signUpbyMobile, this.signUpbyMail);
            }
        },
        mProPageClick() {
            jm('#mProPage').hide();
            jm('#signupPage').show();
            jm('#webHeaderBox').show();
        },
        mobileCtClick(e) {
            selectAnimationCtrl(e.target);
        },
        mobileCtBlur(e) {
            selectAnimation(e.target);
        },
        mobileCtChange(e) {
            signUpPersInfoChangeMobileCt(e.target);
        },
        itemEditClick(e) {
            selectAnimationCtrl(e.target);
        },
        itemEditBlur(e) {
            selectAnimation(e.target);
        },
        addMustTip(item) {
            if (item.must == 'isCheck') {
                return item.propPlaceholder + this.LS.mobi_member_Must;
            } else {
                return item.propPlaceholder;
            }
        },
        signMobileCodeClick() {
            getSignMobileCode(this.showCaptcha);
        },
    },
};
</script>
<style lang="scss"></style>
