var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c("div", { attrs: { id: "mLogin" } }, [
      _c("div", { staticClass: "loginHeader webHeaderBg profileLoginHeader" }, [
        _c("a", {
          staticClass: "g_close icon-gClose",
          on: { click: _vm.closeClick },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "title pageTitle",
          domProps: { textContent: _vm._s(_vm.LS.col_member_login) },
        }),
      ]),
      _vm._v(" "),
      _vm.manageMode
        ? _c("div", { staticClass: "notice" }, [
            _vm._v("您目前处于网站管理状态，请先退出再登录"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "loginPersonIcon" }, [
        _c("div", { staticClass: "personPic" }, [
          _c("div", { staticClass: "icon-personPic" }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "loginContent mloginContent" },
        [
          _c("div", { staticClass: "g_globalLine forAcctLogin" }, [
            _c("input", {
              staticClass: "loginName itemEdit g_input mustItem loginMustItem",
              attrs: {
                id: "user",
                type: "text",
                maxlength: _vm.memberDef.Limit.ACCT_MAXLEN,
                placeholder: _vm.loginNamePlaceHolder,
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "icon-userIcon g_mainColor" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "g_globalLine forAcctLogin" }, [
            _c("input", {
              staticClass:
                "loginPassword itemEdit g_input mustItem loginMustItem",
              attrs: {
                id: "password",
                type: "password",
                autocomplete: "off",
                onpaste: "return false;",
                maxlength: _vm.memberDef.Limit.PWD_MAXLEN,
                placeholder: _vm.pleaseInputStr + _vm.LS.mobi_member_Password,
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "icon-pswIcon g_mainColor" }),
          ]),
          _vm._v(" "),
          _vm.signUpbyMobile
            ? [
                _c("div", { staticClass: "g_globalLine forFastLogin" }, [
                  _c("input", {
                    staticClass: "itemEdit loginTel g_input mustItem",
                    attrs: {
                      id: "acquirerInput",
                      type: "text",
                      placeholder: _vm.LS.memberDialogPlzEnterMobile,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "icon-fastUserIcon g_mainColor" }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "g_globalLine forFastLogin",
                    staticStyle: { "font-size": "0" },
                  },
                  [
                    _c("input", {
                      staticClass: "loginTelPassword itemEdit g_input mustItem",
                      attrs: {
                        id: "memCodeInput",
                        placeholder: _vm.LS.msgBoardValidateCode,
                      },
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "icon-fastCodeIcon g_mainColor",
                    }),
                    _vm._v(" "),
                    _c("div", {
                      class: _vm.acquireCodeClass,
                      attrs: { id: "acquireCode" },
                      domProps: {
                        textContent: _vm._s(_vm.LS.mobi_getMobileCode),
                      },
                      on: { click: _vm.loginAcquireCodeClick },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      staticStyle: { display: "none" },
                      attrs: { id: "memCodeSign" },
                    }),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "memCodeSplitLine" } }),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "loginCaptchaCtrl g_globalLine" }, [
            _c("input", {
              staticClass: "captchaText g_input",
              attrs: {
                id: "loginCaptcha",
                type: "text",
                maxlength: "4",
                placeholder: _vm.LS.mobi_EnterVerificationCode,
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "captchaImg",
              attrs: { id: "loginCaptchaImg", src: _vm.loginCaptchaImgSrc },
              on: { click: _vm.validateCodeClick },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "icon-loginCaptchaTip" }),
          ]),
          _vm._v(" "),
          _vm.mbAutoLogin
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "autoLoginCtrl g_globalLine",
                    staticStyle: { "text-align": "left" },
                  },
                  [
                    _c("input", {
                      staticClass: "autoLoginCheck",
                      attrs: { id: "autoLogin", type: "checkBox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "autoLoginLabel",
                        attrs: { for: "autoLogin" },
                        on: { click: _vm.checkLabelClick },
                      },
                      [
                        _c("span", { staticClass: "icon-uncheckIcon" }),
                        _vm._v(
                          _vm._s(_vm.LS.memberLogin_rememberMe) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "g_globalLine forAcctLogin" }, [
            _c("input", {
              staticClass: "g_button sendIcon g_main_bgColor_v3 fpw_btn",
              attrs: { type: "button", value: _vm.LS.memberLoginLoginV3 },
              on: { click: _vm.memberLoginClick },
            }),
          ]),
          _vm._v(" "),
          _vm.signUpbyMobile
            ? [
                _c("div", { staticClass: "g_globalLine forFastLogin" }, [
                  _c("input", {
                    staticClass: "g_button sendIcon g_main_bgColor_v3 fpw_btn",
                    attrs: { type: "button", value: _vm.LS.memberLoginLoginV3 },
                    on: { click: _vm.fastLoginClick },
                  }),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "loginBottom g_globalLine",
              style: _vm.loginBottomStyle,
            },
            [
              _vm.signUpbyMobile
                ? [
                    _c("div", { style: _vm.fastLoginStyle }, [
                      _c("span", {
                        staticClass:
                          "g_mainColor forAcctLogin loginToggle loginOption",
                        style: _vm.styleOnlyTwoProp,
                        attrs: { id: "mobileFastLoginToggle" },
                        domProps: {
                          textContent: _vm._s(_vm.LS.mobileFastLogin),
                        },
                        on: { click: _vm.mobileFastLoginToggleClick },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "g_mainColor forFastLogin loginToggle loginOption",
                        style: _vm.styleOnlyTwoProp,
                        attrs: { id: "acctLoginToggle" },
                        domProps: {
                          textContent: _vm._s(_vm.LS.memberAcctLogin),
                        },
                        on: { click: _vm.acctLoginToggleClick },
                      }),
                      _vm._v(" "),
                      _vm.findPwdOpen
                        ? _c("a", {
                            staticClass: "g_mainColor freeRegister loginOption",
                            attrs: { href: _vm.findPwdUpUrl },
                            domProps: {
                              textContent: _vm._s(_vm.LS.memberFindPwdTwo),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isCn
                        ? _c("a", {
                            staticClass: "g_mainColor freeRegister loginOption",
                            style: _vm.styleOnlyTwoPropMarginLeft,
                            attrs: { href: _vm.signUpUrl },
                            domProps: {
                              textContent: _vm._s(_vm.LS.memberLogin_freeReg),
                            },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    !_vm.isCn
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-top": "0.5rem",
                            },
                          },
                          [
                            _c("a", {
                              staticClass:
                                "g_mainColor freeRegister loginOption",
                              style: _vm.styleOnlyTwoPropMarginLeft,
                              attrs: { href: _vm.signUpUrl },
                              domProps: {
                                textContent: _vm._s(_vm.LS.memberLogin_freeReg),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.findPwdOpen
                      ? _c("a", {
                          staticClass: "g_mainColor freeRegister loginOption",
                          attrs: { href: _vm.findPwdUpUrl },
                          domProps: {
                            textContent: _vm._s(_vm.LS.memberFindPwdTwo),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("a", {
                      staticClass: "g_mainColor freeRegister loginOption",
                      attrs: { href: _vm.signUpUrl },
                      domProps: {
                        textContent: _vm._s(_vm.LS.memberLogin_freeReg),
                      },
                    }),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _vm.qqOpen || _vm.sinaOpen || _vm.wxOpen
            ? [
                _c("div", { staticClass: "oLgnPanel" }, [
                  _c("div", { staticClass: "head" }, [
                    _c("div", { staticClass: "leftLine" }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "middle",
                      domProps: {
                        textContent: _vm._s(_vm.LS.mobi_member_OtherLgnV3),
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "rightLine" }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "oLgn" }, [
                    _vm.qqOpen
                      ? _c(
                          "div",
                          {
                            staticClass: "qqLgnPanel icon-qqLgnPanel",
                            staticStyle: { "margin-left": "0rem" },
                            attrs: { id: "qqLgn" },
                          },
                          [
                            _c("div", {
                              staticClass: "desc",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.LS.mobi_member_OtherLgnQQ
                                ),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sinaOpen
                      ? _c(
                          "div",
                          {
                            staticClass: "sinaLgnPanel icon-sinaLgnPanel",
                            style: _vm.sinaLgnStyle,
                            attrs: { id: "sinaLgn" },
                          },
                          [
                            _c("div", {
                              staticClass: "desc",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.LS.mobi_member_OtherLgnWB
                                ),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.wxOpen
                      ? _c(
                          "div",
                          {
                            staticClass: "wxLgnPanel icon-wxLgnPanel",
                            style: _vm.wxLgnStyle,
                            attrs: { id: "wxLgn" },
                          },
                          [
                            _c("div", {
                              staticClass: "desc",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.LS.mobi_member_OtherLgnWX
                                ),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mobiWxOpen
                      ? _c(
                          "div",
                          {
                            staticClass: "wxLgnPanel icon-wxLgnPanel",
                            staticStyle: { display: "none" },
                            attrs: { id: "wxLgn" },
                          },
                          [
                            _c("div", {
                              staticClass: "desc",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.LS.mobi_member_OtherLgnWX
                                ),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { display: "none" }, attrs: { id: "fillAcctInfo" } },
      [
        _c(
          "div",
          { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
          [
            _c("span", {
              staticClass: "g_close icon-gClose",
              attrs: { id: "fillInfoBack" },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "title pageTitle",
              domProps: {
                textContent: _vm._s(_vm.LS.mobi_member_OtherLgnFillAcct),
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "loginContent" }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { display: "none" }, attrs: { id: "bindOrLogin" } },
      [
        _c(
          "div",
          { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
          [
            _c("span", {
              staticClass: "g_close icon-gClose",
              attrs: { id: "bindOrLoginBack" },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "title pageTitle",
              domProps: {
                textContent: _vm._s(_vm.LS.mobi_member_OtherLgnMode),
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "loginContent" }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "none" }, attrs: { id: "bindNow" } }, [
      _c("div", { staticClass: "loginHeader webHeaderBg profileLoginHeader" }, [
        _c("span", {
          staticClass: "g_close icon-gClose",
          attrs: { id: "bindNowBack" },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "title pageTitle",
          domProps: {
            textContent: _vm._s(_vm.LS.mobi_member_OtherLgnBindAcct),
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "loginContent" }),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "none" }, attrs: { id: "mSignUp" } }, [
      _c("div", { staticClass: "loginHeader webHeaderBg profileLoginHeader" }, [
        _c("span", {
          staticClass: "g_close icon-gClose",
          attrs: { id: "signUpBack" },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "title pageTitle",
          domProps: { textContent: _vm._s(_vm.LS.col_member_signup) },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "loginContent" }),
      _vm._v(" "),
      _c("div", { staticClass: "checkEmail", attrs: { id: "checkEmail" } }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "emailAddr",
          staticStyle: { display: "none" },
          attrs: { id: "emailAddr", target: "_blank" },
        },
        [_vm._v("地址")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }