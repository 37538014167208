<template>
    <module-frame :module-id="moduleId">
        <div
            v-if="isEmptyProductInfo || (notAdded && !_manageMode)"
            :id="productNewDetailPanelId"
            class="productNewDetailPanel J_pd_detail"
            :class="productDetailVersionClass"
            :data-content-type="knowPayConentTypeProperty"
        >
            <div
                :id="productDetailHeaderId"
                class="productDetailHeader detailHeaderColor"
                style="position: relative"
                :style="mobiTipsStyle"
            >
                <a class="icon-gClose g_round g_iconMiddle proBack icon-defaultColor" @click="back"></a>
                <a class="icon-gHome g_round g_iconMiddle proOther icon-defaultColor" :href="linkLang"></a>
            </div>
            <div class="productNotAdded productNotAdded-icon"></div>
            <div class="productNotAdded-tip">{{ productNotAdded }}</div>
        </div>
        <div
            v-else
            :id="productNewDetailPanelId"
            :class="productNewDetailPanelClass"
            :data-content-type="knowPayConentTypeProperty"
        >
            <!-- 头图按钮 -->
            <div
                :id="productDetailHeaderId"
                class="productDetailHeader detailHeaderColor"
                style="background: rgba(255, 255, 255, 0.992157)"
                :style="mobiTipsStyle"
            >
                <!-- 返回按钮 -->
                <span class="icon-gClose g_round g_iconMiddle proBack icon-defaultColor"></span>
                <!-- 折叠入口 -->
                <span class="icon-gOther g_round g_iconMiddle proOther icon-defaultColor"></span>
                <div class="proOtherPanel">
                    <!-- 首页按钮 -->
                    <a
                        class="icon-gHome g_iconMiddle g_round proHome icon-defaultColor"
                        :href="linkLang + '/index.jsp'"
                    ></a>
                    <!-- 分享按钮 -->
                    <span
                        v-if="openShare === 0"
                        id="productDetailShare"
                        class="icon-share g_iconMiddle g_round proShare icon-defaultColor"
                    ></span>
                </div>
                <!-- 产品标题 -->
                <div
                    v-if="productDetail_v3"
                    :id="productDetailV3_titleId"
                    class="productDetailV3_title"
                    style="display: none"
                    >{{ productName }}</div
                >
            </div>

            <div class="productNewDetail" :showSaleNoEnd="isNoEndTime">
                <div style="position: relative">
                    <div :id="productNewDetailSwipeId" class="proImgSwipe">
                        <div class="proDetailImgBox" :style="proDetailImgBoxStyle">
                            <template v-if="openKnowPayment">
                                <!-- 知识付费-视频 -->
                                <div v-if="isVideoPayProduct" class="knowPayProduct">
                                    <div
                                        class="proDetailImg J_img_lazyload"
                                        :class="imgZoomClass"
                                        :style="proDetailImgStyle"
                                        :src-original="picPath"
                                    >
                                        <template v-if="isFinPay">
                                            <div
                                                class="videoPlay faisco-icons-"
                                                @click="checkKnowPayVideoHandler(productId)"
                                            ></div>
                                            <div id="knowproductVideoMask" style="display: none">
                                                <div class="mobiProductVideoDialog productKnowPayVideo">
                                                    <video
                                                        id="knowPayVideo"
                                                        :style="knowPayVideoStyle"
                                                        class="mobiVideo"
                                                        controls
                                                        webkit-playsinline="true"
                                                        playsinline="true"
                                                        controlsList="nodownload"
                                                        preload="auto"
                                                    ></video>
                                                </div>
                                                <div class="videoQuit" @click="productKnowPayVideoQuit">
                                                    <svg class="videoQuit-close">
                                                        <use href="#jzm-vicon_close"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <template v-if="finalOpener">
                                                <div
                                                    class="videoPlay faisco-icons-"
                                                    @click="checkKnowPayVideoHandler(productId, finalOpener)"
                                                ></div>
                                                <div class="videoTryTipContainer">
                                                    <div class="videoTryBg"></div>
                                                    <div class="videoTryTip">
                                                        <span>可试看前{{ videoTryMin }}分钟，购买后可收看完整内容</span>
                                                    </div>
                                                </div>
                                                <div id="knowproductVideoMask" style="display: none">
                                                    <div class="mobiProductVideoDialog productKnowPayVideo">
                                                        <video
                                                            id="knowPayVideo"
                                                            :style="knowPayVideoStyle"
                                                            class="mobiVideo"
                                                            controls
                                                            webkit-playsinline="true"
                                                            playsinline="true"
                                                            controlsList="nodownload"
                                                            preload="auto"
                                                        ></video>
                                                    </div>
                                                    <div class="videoQuit" @click="productKnowPayVideoQuit">
                                                        <svg class="videoQuit-close">
                                                            <use href="#jzm-vicon_close"></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="videoPlay faisco-icons-"
                                                    @click="checkKnowPayVideoHandler(productId)"
                                                ></div>
                                                <div id="knowproductVideoMask" style="display: none">
                                                    <div class="mobiProductVideoDialog productKnowPayVideo"></div>
                                                    <div class="videoQuit" @click="productKnowPayVideoQuit">
                                                        <svg class="videoQuit-close">
                                                            <use href="#jzm-vicon_close"></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                                <!-- 知识付费-图文、专栏 -->
                                <template v-else-if="isPicTextProduct || isColumnProduct">
                                    <template v-if="picPathList.length !== 0">
                                        <div v-for="pic in picPathList" class="swipImgBox">
                                            <div
                                                class="proDetailImg J_img_lazyload"
                                                :class="imgZoomClass"
                                                :vwidth="pic.width"
                                                :vheight="pic.height"
                                                :alt="productName"
                                                :data-picId="pic.id"
                                                :style="proDetailImgStyle"
                                                :src-original="pic.path"
                                            >
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <div
                                                class="proDetailImg J_img_lazyload"
                                                :class="imgZoomClass"
                                                :style="proDetailImgStyle"
                                                :src-original="defaultPath"
                                            >
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <!-- 知识付费-音频 -->
                                <div v-else-if="isAudioProduct" id="productAudioPlayer">
                                    <product-audio-player
                                        :pic-path="productAudioPicPath"
                                        :is-default-color-style="isDefaultColorStyle"
                                        :audio-try-min="audioTryMin"
                                        :follow-color="followColor"
                                        :img-zoom-class="imgZoomClass"
                                        :is-fin-pay="isFinPay"
                                        :istry-open="istryOpen"
                                        :is-use-custom-color="isUseCustomColor"
                                        :is-v1="isV1"
                                        :product-id="productId"
                                    ></product-audio-player>
                                </div>
                            </template>

                            <!-- 头图 -->
                            <template v-else>
                                <!-- 多张头图 -->
                                <template v-if="picPathList.length !== 0">
                                    <div v-for="(pic, index) in picPathList" class="swipImgBox">
                                        <div
                                            class="proDetailImg J_img_lazyload"
                                            :class="imgZoomClass"
                                            :vwidth="pic.width"
                                            :vheight="pic.height"
                                            :alt="productName"
                                            :src-original="pic.newPath"
                                            :data-picId="pic.id"
                                            :style="proDetailImgStyle"
                                        >
                                        </div>
                                        <!-- 主图视频 -->
                                        <template v-if="showVideo && index === 0">
                                            <div class="videoPlay faisco-icons-" @click="playVideo"></div>
                                            <div id="productVideoMask" class="productVideoMask" style="display: none">
                                                <div class="mobiProductVideoDialog">
                                                    <video
                                                        id="productVideo"
                                                        :style="knowPayVideoStyle"
                                                        class="mobiVideo"
                                                        controls
                                                        ><source
                                                            :src="flvPagePath"
                                                            type="video/mp4"
                                                        />抱歉，您的浏览器不能查看该视频。</video
                                                    >
                                                </div>
                                                <div class="videoQuit" @click="quitVideo">
                                                    <svg class="videoQuit-close">
                                                        <use href="#jzm-vicon_close"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <!-- 没有头图 -->
                                <template v-else>
                                    <div>
                                        <div
                                            class="proDetailImg J_img_lazyload"
                                            :class="imgZoomClass"
                                            :style="proDetailImgStyle"
                                            :src-original="defaultPath"
                                        ></div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div
                            v-if="!openKnowPayment && !isNewThemeAndPageStyleHavePicture"
                            class="productNameSwipBox"
                            :style="productNameSwipBoxStyle"
                        >
                            <h1 class="g_displayClamp2" :style="ellipsisStyle">{{ productName }}</h1>
                        </div>
                        <div
                            v-if="!openKnowPayment && picPathList.length > 1 && pageStyle != 1"
                            id="productBullet"
                            class="productBullet"
                        >
                            <ul id="bullets" class="bullets">
                                <li v-for="(item, index) in picPathList" :class="{ on: index === 0 }"></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="mallThemeProductWrap">
                    <h1 class="productNameText" style="word-break: normal; word-wrap: break-word">{{ productName }}</h1>
                    <div class="mallProductToolBtns">
                        <div v-if="openShare === 0" id="mallThemeProductDetailShare" class="mallShareIcon">
                            <div class="icon-share g_iconMiddle proShare icon-defaultColor"></div>
                            <div class="shareText">{{ share }}</div>
                        </div>
                        <!-- 收藏 -->
                        <div v-if="shareHtml" class="mallCollectionIcon shareBlock" :class="shareTopClass">
                            <div class="icon-collection" :class="collectionClass"></div>
                            <div class="collectText">{{ collect }}</div>
                        </div>
                    </div>
                </div>

                <div
                    class="pd3ProductNameContent"
                    :class="{ pd3NoCollection: shareHtml === '' }"
                    style="position: relative"
                    :style="pd3ProductNameContentStyle"
                >
                    <h1 class="pd3ProductName" :style="ellipsisStyle">{{ productName }}</h1>
                    <!-- 收藏 -->
                    <div v-if="shareHtml" class="mallCollectionIcon shareBlock" :class="shareTopClass">
                        <div class="icon-collection" :class="collectionClass"></div>
                        <div class="collectText">{{ collect }}</div>
                    </div>
                </div>

                <div
                    v-if="
                        mallPriceHtml !== '' ||
                        mallMarketPriceHtml !== '' ||
                        shareHtml !== '' ||
                        (isNewTheme && isBeginTimeGroundDown)
                    "
                    :class="productPriceClass"
                    :style="productPriceStyle"
                >
                    <div
                        v-if="
                            isKnowPayProduct &&
                            openKnowPayment &&
                            (isMallTheme || (productDetail_v3 == '' && newCountDownProductName == ''))
                        "
                        class="newCountDownProductName"
                    >
                        <h1 class="pd3ProductName" :style="ellipsisStyle">{{ productName }}</h1>
                    </div>

                    <span
                        v-if="mallPriceHtml === '' && mallMarketPriceHtml === ''"
                        class="mallPrice hiddenMallPrice"
                        :style="pageStyle == 1 ? 'height:1.8rem;line-height:1.8rem;' : ''"
                    ></span>

                    <!-- TODO: 这里直接插入html不生效 -->
                    <div v-if="isLoadOldCountDownInManageOrOldTheme" v-html="mallPriceHtml"></div>
                    <!-- TODO: 这里直接插入html不生效 -->
                    <div v-if="!showNewMarketPrice" v-html="mallMarketPriceHtml"></div>

                    <div
                        v-if="productDetail_v3 === '' && shareHtml"
                        class="mallCollectionIcon shareBlock"
                        :class="shareTopClass"
                    >
                        <div class="icon-collection" :class="collectionClass"></div>
                        <div class="collectText">{{ collect }}</div>
                    </div>
                    <!-- TODO: 这里直接插入html不生效 -->
                    <div
                        v-if="showNewMarketPrice && isLoadOldCountDownInManageOrOldTheme"
                        v-html="mallMarketPriceHtml"
                    ></div>
                </div>

                <template v-if="showProductGuaranteePanel">
                    <!-- 入口 -->
                    <div id="guaranteeContent" class="productGuarantee J_guarantee" :style="guaranteeContentStyle">
                        <div v-for="guarantee in guaranteeList" v-if="guarantee.show" class="guaranteeName">
                            <i class="icon-guarantee g_mainColor"></i>
                            {{ guarantee.name }}
                        </div>
                        <div class="faisco-icons-forward1 g_middleColor more"></div>
                    </div>
                    <!-- 展开面板 -->
                    <div id="productGuaranteePanel" class="productGuaranteePanel" style="display: none">
                        <div id="closeProductGuarantee" style="height: 100%"></div>
                        <div class="productGuaranteeContentPanel">
                            <div class="title">产品保证</div>
                            <template v-for="guarantee in guaranteeList" v-if="guarantee.show">
                                <div class="guaranteeName"
                                    ><i class="icon-guarantee g_mainColor"></i>{{ guarantee.name }}</div
                                >
                                <div v-if="guarantee.e" class="guaranteeContent" v-html="guarantee.e"></div>
                            </template>
                            <div id="guaranteeBtn" class="confirmBtn g_bgColor" :style="guaranteeBtnStyle"
                                >我知道了</div
                            >
                        </div>
                    </div>
                </template>
                <div class="mallThemeSeparator g_breakline"></div>
                <!-- 购物选项列表 -->
                <div v-if="mallOptionsOpen" id="productInfoSize" class="proCommProperty productInfoSize borderClass">
                    <span class="productParamterNameList pdInfoTitle g_middleColor" v-text="LS.select"></span>
                    <span class="productParamterWords">{{ productParamterNameList }}</span>
                    <span class="choiceProductSize icon-gGoforward g_iconMiddle g_middleColor"></span>
                </div>
                <div class="mallThemeSeparator g_breakline"></div>
                <div v-if="showFileDownload" class="detail_file_download">
                    <span class="label" v-text="downloadModel.downloadBtnText"></span>
                    <span class="filename" v-text="downloadModel.fileName"></span>
                    <a :href="downloadUrl" class="file_download_link" @click="downloadClick">
                        <svg class="download">
                            <use href="#download"></use>
                        </svg>
                    </a>
                </div>
                <div v-if="mallOptionsOpen" class="mallThemeSeparator g_breakline"></div>

                <div
                    :id="productDetailTabPanelClass"
                    class="productDetailTabPanel"
                    :class="btnSpaceClass"
                    :style="btnSpaceStyle"
                >
                    <ul id="productTabUl" class="tabUl" :style="mobiTipsStyle">
                        <div class="tabUiDiv">
                            <!--  TODO:将html逻辑移动到前端 -->
                            <div class="tabUiDiv2" :style="tabLiDivStyle" v-html="tabHeadHtml"> </div>
                        </div>
                    </ul>
                    <!--  TODO:将html逻辑移动到前端 -->
                    <html-render ref="tabBody" class="tabBody" :html="tabBodyHtml"></html-render>
                </div>

                <div
                    v-if="bookingOpen || productFormOpen || immediatelyConsultOpen || openKnowPayment"
                    class="fixedWrap"
                >
                    <div class="productMallShop" :class="fixedWrapClass">
                        <template v-if="openKnowPayment">
                            <div
                                v-if="isBelongToColumnProduct"
                                id="J_courseCatalogBtn"
                                class="mallThemeAddCartBtn productAddMall g_mallButton"
                                >课程目录</div
                            >
                            <div
                                id="J_productKnowPayBtn"
                                class="bookingAddCartBtn productImmediatelyBug g_bookingButton J_productBookingBtn"
                                :class="productKnowPayBtnClass"
                                :style="isFinPay ? 'opacity: 0.5' : ''"
                                @click="productKnowPayBtnClickHandler"
                            >
                                {{ knowPaymentText }}
                            </div>
                        </template>
                        <template v-else>
                            <a
                                v-if="immediatelyConsultOpen"
                                id="consultImmediatelyBtn"
                                :href="advisoryUrl"
                                class="consultImmediatelyBtn productImmediatelyBug g_bookingButton g_conSultBtn"
                                :class="consultImmediatelyBtnClass"
                                :_pid="productId"
                            >
                                {{ advisoryTxt }}
                            </a>
                            <!-- 立即预约按钮 -->
                            <div
                                v-if="bookingOpen || productFormOpen"
                                id="J_productBookingBtn"
                                class="bookingAddCartBtn productImmediatelyBug g_bookingButton J_productBookingBtn"
                                :class="bookingAddCartBtnClass"
                                :_pid="productId"
                                @click="bookingButtonClickHandler"
                                v-html="shopBtnTypeText"
                            >
                            </div>
                        </template>
                    </div>
                </div>

                <div
                    v-else-if="openService === 0 && _manageMode"
                    id="serviceBtnOuter"
                    class="serviceBtnOuter productMallShop"
                    :class="foreignClass"
                >
                    <div id="serviceBtn" class="serviceBtn noMallServiceBtn"
                        >"
                        <div class="serviceInner">
                            <div class="icon-serviceBtn serviceBtnIcon"></div>
                            <span class="serviceBtnText">{{ mobiService }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 产品评论 TODO: 这里会用js移动节点位置，可以优化 -->
            <div id="userCommentPanel" class="userCommentPanel" style="display: none">
                <div class="userCommentHead">
                    <span class="userCommentGoBack icon-gClose g_iconMiddle"></span>
                    {{ mobiPostComments }}
                    <a class="userCommentGoHome icon-gHome g_iconMiddle" :href="linkLang"> </a>
                </div>
                <div class="userCommentBody">
                    <!-- 登录评论、匿名评论tab -->
                    <ul v-if="!isOnlyMember" class="tabUl">
                        <li id="tabUserLi1" class="tabLi tabActive" data="1">{{ mobiLogComments }}</li>
                        <li id="tabUserLi2" class="tabLi" data="2">{{ mobiIncognitoComments }}</li>
                    </ul>
                    <div class="tabBody">
                        <!-- 登录评论 -->
                        <div id="tabUserPanel1" class="tabPanel tabPanelAtive">
                            <div v-if="_manageMode" class="notice">您目前处于网站管理状态，请先退出再评论</div>
                            <div class="userCommentInfo">
                                <div class="g_globalLine">
                                    <input
                                        v-if="_sessionMemberId <= 0"
                                        id="user"
                                        type="text"
                                        maxlength="50"
                                        class="itemEdit g_input"
                                        :placeholder="mobiMemberPleaseInput + mobiMemberAccount"
                                    />
                                    <input
                                        v-else
                                        id="user"
                                        type="text"
                                        maxlength="50"
                                        disabled="true"
                                        :value="memberName"
                                        class="itemEdit g_input"
                                        :minlength="CREATOR_MINLEN"
                                        :placeholder="mobiMemberPleaseInput + mobiMemberAccount"
                                    />
                                    <span class="icon-mnameIcon"></span>
                                </div>

                                <div v-if="_sessionMemberId <= 0" class="g_globalLine">
                                    <input
                                        id="password"
                                        type="password"
                                        onpaste="return false;"
                                        autocomplete="off"
                                        maxlength="20"
                                        class="itemEdit g_input"
                                        :placeholder="mobiMemberPleaseInput + mobiMemberPassword"
                                    />
                                    <span class="icon-mpswIcon"></span>
                                </div>

                                <div v-if="openCommStar" class="l-star-list J-star-list" _star="5">
                                    <li class="faisco-icons-star3"></li>
                                    <li class="faisco-icons-star3"></li>
                                    <li class="faisco-icons-star3"></li>
                                    <li class="faisco-icons-star3"></li>
                                    <li class="faisco-icons-star3"></li>
                                    <li class="score-tip"></li>
                                </div>

                                <div id="userCommentRemarkPanel1">
                                    <div class="g_globalLine">
                                        <textarea
                                            id="userCommentRemark"
                                            class="itemEdit msgIcon g_textArea icon-msgIcon"
                                            :minlength="COMMENT_MINLEN"
                                            :maxlength="COMMENT_MAXLEN"
                                            :placeholder="mobiMemberPleaseInput + msgBoardContent"
                                        ></textarea>
                                        <span class="icon-mmsgIcon"></span>
                                    </div>
                                    <div class="g_globalLine J_productCommImg upImgSet">
                                        <table id="pt_add_img_tb" cellpadding="0" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        id="pt_add_img_tb"
                                                        class="comm-find-tb"
                                                        :maxNum="COMMENT_UP_IMG_MAX_NUM"
                                                    >
                                                        <div id="comm-img-swfu-placeholder"></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div id="userCommentLoginAndComform" class="userCommentComform g_button">{{
                                    mobiSubmit
                                }}</div>
                            </div>
                        </div>
                        <!-- 匿名评论 -->
                        <div v-if="!isOnlyMember" id="tabUserPanel2" class="tabPanel">
                            <div v-if="_manageMode" class="notice">您目前处于网站管理状态，请先退出再评论</div>
                            <div id="userCommentInfo2" class="userCommentInfo">
                                <div class="g_globalLine">
                                    <input
                                        id="anonymityCommentUser"
                                        type="text"
                                        maxlength="50"
                                        class="itemEdit g_input"
                                        :placeholder="mobiMemberPleaseInput + productCommentCreator"
                                    />
                                    <span class="icon-mnameIcon"></span>
                                </div>

                                <div v-if="openCommStar" class="l-star-list J-star-list" _star="5">
                                    <li class="faisco-icons-star3"></li>
                                    <li class="faisco-icons-star3"></li>
                                    <li class="faisco-icons-star3"></li>
                                    <li class="faisco-icons-star3"></li>
                                    <li class="faisco-icons-star3"></li>
                                    <li class="score-tip"></li>
                                </div>
                                <div id="userCommentRemarkPanel2"></div>
                                <div
                                    id="anonymityCommentComform"
                                    class="userCommentComform g_button g_main_bgColor_v3"
                                    >{{ mobiSubmit }}</div
                                >
                            </div>
                        </div>
                        <input id="postCommentId" type="hidden" :value="productId" />
                    </div>
                </div>
            </div>

            <!-- 购物选项：TODO 这里会用js移动节点位置，可以优化 -->
            <div
                id="productMallOptionPanel"
                class="productMallOptionPanel"
                :class="productDetail_v3"
                style="display: none"
            >
                <div class="productMallOptionContentPanel">
                    <div class="productMallOptionContent" style="bottom: 0rem; padding-bottom: 0.3rem">
                        <div class="productMallOptionHead">
                            <div class="productMallOptionImage">
                                <div class="productMallOptionImageContent">
                                    <span class="imageMiddleSpan"></span>
                                    <img
                                        class="J_mallOptionImg mallOptionImg"
                                        :picId="firstPicId"
                                        isOptImg="false"
                                        :src="firstNewPath"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="productDetail">
                            <div class="productDetailName">
                                <div class="productName g_middleColor">
                                    <div class="productNameWrap">{{ productName }}</div>
                                </div>
                                <!-- TODO: html处理 -->
                                <div v-if="mallPriceHtml2" v-html="mallPriceHtml2"> ></div>
                            </div>
                        </div>
                        <div class="productDetailClose icon-Off g_iconMiddle icon-defaultColor"></div>

                        <!-- 构想选项SKU -->
                        <div v-if="showOptionList.length" id="mallOptionAndNumContainer" class="mallOptionAndNumBox">
                            <div id="mallOptionAndNumBox">
                                <!-- 购物选项组合 -->
                                <div class="mallNewOptionBox mallOptionBox J-op-box" v-html="mallNewOptionBoxHtml">
                                </div>

                                <div v-if="optionPicList.length" :id="productDetailOptSwipeId" class="proImgSwipe">
                                    <div class="proDetailImgBox" style="display: none">
                                        <div class="swipImgBox">
                                            <div
                                                v-for="optionPic in optionPicList"
                                                class="proDetailImg"
                                                :class="imgZoomClass"
                                                :vwidth="optionPic.width"
                                                :vheight="optionPic.height"
                                                :alt="productName"
                                                :data-picId="optionPic.picId"
                                                :style="proDetailImgStyle"
                                                :src-original="optionPic.newPath"
                                            >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="g_separator separatorLine" style="margin: 0 0.75rem"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </module-frame>
</template>

<script>
import { ing, showTopTips } from '@/shared/tips';
import { initProductOptions } from '@/modules/shared/product/productOptions';
import { consultImmediatelyBtnClick } from '@/modules/shared/product/consultImmediate';
import {
    bookingPanelClickEvent,
    setMemberPriceLabelColot2ThemeBackgroundColor,
    hideFooterBox,
    showFooterBox,
    initOptionsStr,
} from '@/modules/shared/product/product';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { productKnowPayBtnClick } from '@/modules/shared/product/knowPay/btn';
import { initMbPdCollection } from '@/modules/shared/product/collection';
import { initVideo } from '@/modules/shared/product/video';
import { initProductSwipe } from '@/modules/shared/product/swipe';
import { initPbCommUploadImgParam } from '@/modules/shared/product/upload';
import { initPictureSlidesV4 } from '@/shared/photo/slides';
import { banCurPageContentCopy } from '@/shared/util';
import { setPdMallPriceHeight, showNewMarketPrice, setMarketingProPdStyle } from '@/modules/shared/product/layout';
import { productGuaranteeEventInit } from '@/modules/shared/product/guarantee';
import { initCourseCatalog } from '@/modules/shared/product/knowPay/column';
import { setStarSelect } from '@/modules/shared/product/star';
import {
    mallProductDetailFixedBar,
    fixPorductDetailStyle,
    initCustab,
    productNewDetailEventInit,
} from '@/modules/shared/product/detail';
import { allFixWXPA } from '@/system/shared/mobiPATipFix';
import { initShowMallOptionImg } from '@/modules/shared/product/productOptions';
import { bindServiceBtnEvent } from '@/modules/shared/product/btn';
import { videoQuit } from '@/features/global-mobi-function/methods/share';
import { getProductVideo } from '@/modules/shared/product/video';
import { productFormBtnClick } from '@/modules/shared/product/btn';
import { checkKnowPayVideo, productKnowPayVideoQuit } from '@/modules/shared/product/knowPay/video';
import { mapGetters, mapMutations, mapState } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import productAudioPlayer from '../../components/productAudioPlayer.vue';
import { COL } from '@/def/col';
import { TEMPLATE_LAYOUT } from '@/def/template';
import { goBack } from '@/features/router/universal';
import { encodeHtml, decodeHtml } from '@/shared/util';

import HtmlRender from '@/components/html-render/index.vue';
import { MOBILE_AUTH_TIPS } from '@/services';
import { format } from '@/shared/fai';
import { navigateToLoginPage } from '@/shared/login';

export default {
    components: {
        ModuleFrame,
        productAudioPlayer,
        HtmlRender,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['openThemeV3', 'colId', 'templateLayoutId', 'LS', 'realNameAuth']),
        ...mapState(['mobiTipList']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        productNewDetailPanelId() {
            return `productNewDetailPanel${this.moduleId}`;
        },
        productDetailHeaderId() {
            return `productDetailHeader${this.moduleId}`;
        },
        productNewDetailSwipeId() {
            return `productNewDetailSwipe${this.moduleId}`;
        },
        knowPayConentTypeProperty() {
            return this.isKnowPayProduct ? this.payContentType : '';
        },
        productNewDetailPanelClass() {
            return [
                'productNewDetailPanel',
                'J_pd_detail',
                this.productDetailVersionClass,
                this.productDetail_v3,
                this.newCountDownProductName,
                this.mallThemeTypeClass,
            ];
        },
        proDetailImgBoxStyle() {
            return this.pageStyle == 1 ? 'display:none;' : '';
        },
        proDetailImgStyle() {
            return {
                height: `${Math.ceil(16 * ((this.f_height * 1.0) / this.f_width))}rem`,
                [`background-image`]: `url(${this.loadingPath})`,
            };
        },
        productDetailTabPanelClass() {
            return `productDetailTabPanel${this.moduleId}`;
        },
        btnSpaceStyle() {
            return this.productTabs === 0 ? 'display:none;' : '';
        },
        extInfo() {
            return this.module.extInfo;
        },
        isEmptyProductInfo() {
            return this.isEmpty(this.extInfo.productInfo) || this.productInfo.id <= 0;
        },
        notAdded() {
            return this.extInfo.notAdded;
        },
        _manageMode() {
            return VITE_APP_MODE !== 'visitor';
        },
        isKnowPayProduct() {
            return this.extInfo.isKnowPayProduct;
        },
        payContentType() {
            return this.extInfo.payContentType;
        },
        productNotAdded() {
            return this.extInfo.productNotAdded;
        },
        linkLang() {
            return this.extInfo.linkLang;
        },
        openShare() {
            return this.extInfo.openShare;
        },
        isNoEndTime() {
            return this.extInfo.isNoEndTime;
        },
        pageStyle() {
            return this.extInfo.pageStyle;
        },
        picPathList() {
            return this.extInfo.picPathList || [];
        },
        productName() {
            return this.extInfo.productName;
        },
        imgZoomClass() {
            return this.extInfo.imgZoomClass;
        },
        loadingPath() {
            return this.extInfo.loadingPath;
        },
        showVideo() {
            return this.extInfo.showVideo;
        },
        flvPagePath() {
            return this.extInfo.flvPagePath;
        },
        memberMinPrice() {
            return this.extInfo.memberMinPrice;
        },
        memberMaxPrice() {
            return this.extInfo.memberMaxPrice;
        },
        authorization() {
            return this.extInfo.authorization;
        },
        showNewMarketPrice() {
            return this.extInfo.showNewMarketPrice;
        },
        mallOptionsOpen() {
            return this.extInfo.mallOptionsOpen;
        },
        productParamterNameList() {
            return this.extInfo.productParamterNameList || [];
        },
        btnSpaceClass() {
            return this.extInfo.btnSpaceClass;
        },
        productTabs() {
            return this.extInfo.productTabs;
        },
        tabLiDivStyle() {
            return this.extInfo.tabLiDivStyle;
        },
        mobiService() {
            return this.extInfo.mobiService;
        },
        bookingOpen() {
            return this.extInfo.bookingOpen;
        },
        immediatelyConsultOpen() {
            return this.extInfo.immediatelyConsultOpen;
        },
        productFormOpen() {
            return this.extInfo.productFormOpen;
        },
        openKnowPayment() {
            return this.extInfo.openKnowPayment;
        },
        advisoryTxt() {
            return this.extInfo.advisoryTxt;
        },
        shopBtnTypeText() {
            return this.extInfo.shopBtnTypeText;
        },
        isBelongToColumnProduct() {
            return this.extInfo.isBelongToColumnProduct;
        },
        knowPaymentText() {
            return this.extInfo.knowPaymentText;
        },
        mobiPostComments() {
            return this.extInfo.mobiPostComments;
        },
        mobiLogComments() {
            return this.extInfo.mobiLogComments;
        },
        mobiIncognitoComments() {
            return this.extInfo.mobiIncognitoComments;
        },
        _sessionMemberId() {
            return this.extInfo._sessionMemberId;
        },
        mobiMemberPleaseInput() {
            return this.extInfo.mobiMemberPleaseInput;
        },
        mobiMemberAccount() {
            return this.extInfo.mobiMemberAccount;
        },
        mobiMemberPassword() {
            return this.extInfo.mobiMemberPassword;
        },
        COMMENT_MINLEN() {
            return this.extInfo.COMMENT_MINLEN;
        },
        COMMENT_MAXLEN() {
            return this.extInfo.COMMENT_MAXLEN;
        },
        COMMENT_UP_IMG_MAX_NUM() {
            return this.extInfo.COMMENT_UP_IMG_MAX_NUM;
        },
        mobiSubmit() {
            return this.extInfo.mobiSubmit;
        },
        productCommentCreator() {
            return this.extInfo.productCommentCreator;
        },
        firstPicId() {
            return this.extInfo.firstPicId;
        },
        firstNewPath() {
            return this.extInfo.firstNewPath;
        },
        siteFormPleaseSelect() {
            return this.extInfo.siteFormPleaseSelect;
        },
        productDetailOptSwipeId() {
            return `productDetailOptSwipe${this.moduleId}`;
        },
        isFinPay() {
            return this.extInfo.isFinPay;
        },
        finalOpener() {
            return this.extInfo.finalOpener;
        },
        videoTryMin() {
            return this.extInfo.videoTryMin;
        },
        picPath() {
            return this.extInfo.picPath;
        },
        mallNewOptionBoxHtml() {
            return this.extInfo.mallNewOptionBoxHtml;
        },

        finallyShowPrices() {
            return this.extInfo.finallyShowPrices;
        },
        isBanCopy() {
            return this.extInfo.isBanCopy;
        },
        optionSlidePicList() {
            return this.extInfo.optionSlidePicList || [];
        },
        productAudioPlayerOption() {
            return this.extInfo.productAudioPlayerOption;
        },
        bssKnowProductType() {
            return this.extInfo.bssKnowProductType;
        },
        isInitProductSwipe() {
            return this.extInfo.isInitProductSwipe;
        },
        COMMENT_UP_IMG_MAX_SIZE() {
            return this.extInfo.COMMENT_UP_IMG_MAX_SIZE;
        },
        COMMENT_UP_IMG_TYPE_VALId() {
            return this.extInfo.COMMENT_UP_IMG_TYPE_VALId;
        },
        siteMainDomain() {
            return this.extInfo.siteMainDomain;
        },
        proSelSignValue() {
            return this.extInfo.proSelSignValue;
        },
        usedAdvisoryMethod() {
            return this.extInfo.usedAdvisoryMethod;
        },
        advisoryPhone() {
            return this.extInfo.advisoryPhone;
        },
        wechatImgPath() {
            return this.extInfo.wechatImgPath;
        },
        mobiAdvisoryIde() {
            return this.extInfo.mobiAdvisoryIde;
        },
        belongToColumnProductId() {
            return this.extInfo.belongToColumnProductId;
        },
        optionsInfo() {
            return this.extInfo.optionsInfo;
        },
        oldOptionsStr() {
            return this.extInfo.oldOptionsStr;
        },
        productRecommentData() {
            return this.extInfo.productRecommentData;
        },
        recommendProOpen() {
            return this.extInfo.recommendProOpen;
        },
        openCommStar() {
            return this.extInfo.openCommStar;
        },
        shareDataProdectDetail() {
            return this.extInfo.shareDataProdectDetail;
        },
        argsParam() {
            return this.extInfo.argsParam;
        },
        wxOpen() {
            return this.extInfo.wxOpen;
        },
        exportData() {
            return this.extInfo.exportData;
        },
        openService() {
            return this.extInfo.openService;
        },
        f_width() {
            return this.extInfo.f_width;
        },
        f_height() {
            return this.extInfo.f_height;
        },
        isBeginTimeGroundDown() {
            return this.extInfo.isBeginTimeGroundDown;
        },
        defaultPath() {
            return this.extInfo.defaultPath;
        },
        mallMarketPriceHtml() {
            return this.extInfo.mallMarketPriceHtml;
        },
        mallPriceHtml() {
            return this.extInfo.mallPriceHtml;
        },
        tabHeadHtml() {
            return this.extInfo.tabHeadHtml;
        },
        tabBodyHtml() {
            return this.extInfo.tabBodyHtml;
        },
        choiceCurrencyVal() {
            return this.extInfo.choiceCurrencyVal;
        },
        productId() {
            return this.productInfo.id;
        },
        productInfo() {
            return this.extInfo.productInfo;
        },
        // E

        productDetailVersionClass() {
            return this.extInfo.productDetailVersionClass;
        },
        productDetail_v3() {
            return this.extInfo.productDetail_v3;
        },
        newCountDownProductName() {
            return this.extInfo.newCountDownProductName;
        },
        mallThemeTypeClass() {
            return this.extInfo.mallThemeTypeClass;
        },
        productDetailV3_titleId() {
            return `productDetailV3_title${this.moduleId}`;
        },
        share() {
            return this.extInfo.share;
        },
        shareTopClass() {
            return this.extInfo.shareTopClass;
        },
        collect() {
            return this.extInfo.collect;
        },
        shareHtml() {
            return this.extInfo.shareHtml;
        },
        isMallTheme() {
            return this.extInfo.isMallTheme;
        },
        pd3ProductNameContentStyle() {
            return this.isMallTheme || (this.productDetail_v3 == '' && this.newCountDownProductName == '')
                ? 'display:none;'
                : '';
        },
        isNewTheme() {
            return this.extInfo.isNewTheme;
        },
        productPriceStyle() {
            return this.pageStyle == 1 ? 'padding-left:0.9rem;' : '';
        },
        proCommProperty() {
            return this.extInfo.proCommProperty;
        },
        pricePlaceHolderClass() {
            return this.extInfo.pricePlaceHolderClass;
        },
        productPriceClass() {
            if (this.memberMinPrice && this.memberMaxPrice && this.authorization) {
                return ['proCommProperty', 'productPrice', 'proCommPropertyPrice'];
            } else {
                return ['productPrice', this.proCommProperty, this.pricePlaceHolderClass];
            }
        },
        isLoadOldCountDownInManageOrOldTheme() {
            return this.extInfo.isLoadOldCountDownInManageOrOldTheme;
        },
        showProductGuaranteePanel() {
            return this.extInfo.showProductGuaranteePanel;
        },
        guaranteeContentStyle() {
            return this.isMallTheme ? '' : 'border-bottom:1px solid #f1f1f1;';
        },
        styleId() {
            return this.extInfo.styleId;
        },
        guaranteeBtnStyle() {
            return this.styleId === 0 ? '' : `background:${this.color}`;
        },
        color() {
            return this.extInfo.color;
        },
        fixedWrapClass() {
            return (
                ((this.bookingOpen || this.productFormOpen) && this.immediatelyConsultOpen ? 'half ' : '') +
                this.foreignClass
            );
        },
        foreignClass() {
            return this.extInfo.foreignClass;
        },
        productKnowPayBtnClass() {
            const tmp = [];

            if (this.isMallTheme) {
                tmp.push('mallThemeImmeBtn');
                tmp.push('g_bookingBtnMallTheme');
            }

            if (this.isNewTheme) {
                tmp.push('isNewTheme');
            }

            return tmp;
        },
        consultImmediatelyBtnClass() {
            const tmp = [];

            if (this.isMallTheme) {
                tmp.push('mallThemeImmeBtn');
                tmp.push('g_bookingBtnMallTheme');
            }

            if (this.isNewTheme) {
                tmp.push('isNewTheme');
            }

            if (this.bookingOpen || this.productFormOpen) {
                tmp.push('productAddMall');
                tmp.push('g_mainColor');
            }

            return tmp;
        },
        bookingAddCartBtnClass() {
            const tmp = [];

            if (this.isMallTheme) {
                tmp.push('mallThemeImmeBtn');
                tmp.push('g_bookingBtnMallTheme');
            }

            if (this.isNewTheme) {
                tmp.push('isNewTheme');
            }

            return tmp;
        },
        mallPriceHtml2() {
            return this.extInfo.mallPriceHtml2;
        },
        isNewThemeAndPageStyleHavePicture() {
            return this.extInfo.isNewThemeAndPageStyleHavePicture;
        },
        productNameSwipBoxStyle() {
            return this.pageStyle == 1
                ? 'position:relative;margin-top:2.4rem;color:#333;background:none;padding:0.8rem 0.8rem 0rem 1.2rem;'
                : '';
        },
        viewAllow() {
            return this.extInfo.viewAllow;
        },
        hasThirdCode() {
            return this.extInfo.hasThirdCode;
        },
        oem() {
            return this.extInfo.oem;
        },
        choiceCurrencyValPure() {
            return this.extInfo.choiceCurrencyValPure;
        },
        isShowCollectionHeadIcon() {
            return this.extInfo.isShowCollectionHeadIcon;
        },
        sessionMid() {
            return this.extInfo.sessionMid;
        },
        collectionList() {
            return this.extInfo.collectionList || [];
        },
        isOpen() {
            return this.extInfo.isOpen;
        },
        isOnlyMember() {
            return this.extInfo.isOnlyMember;
        },
        msgBoardContent() {
            return this.extInfo.msgBoardContent;
        },
        showOptionList() {
            return this.extInfo.showOptionList || [];
        },
        pdNotFound() {
            return this.extInfo.pdNotFound;
        },
        guaranteeList() {
            let list = this.extInfo.guaranteeList || [];
            return list.map((item) => {
                item.e = encodeHtml(decodeHtml(item.e));
                return item;
            });
        },
        isVideoPayProduct() {
            return this.extInfo.isVideoPayProduct;
        },
        isPicTextProduct() {
            return this.extInfo.isPicTextProduct;
        },
        isColumnProduct() {
            return this.extInfo.isColumnProduct;
        },
        optionPicList() {
            return this.extInfo.optionPicList || [];
        },
        isAudioProduct() {
            return this.extInfo.isAudioProduct;
        },
        productAudioPicPath() {
            return this.productAudioPlayerOption.picPath;
        },
        isDefaultColorStyle() {
            return this.productAudioPlayerOption.isDefaultColorStyle;
        },
        audioTryMin() {
            return this.productAudioPlayerOption.audioTryMin;
        },
        followColor() {
            return this.productAudioPlayerOption.followColor;
        },
        istryOpen() {
            return this.productAudioPlayerOption.istryOpen;
        },
        isV1() {
            return this.productAudioPlayerOption.isV1;
        },
        isUseCustomColor() {
            return this.productAudioPlayerOption.isUseCustomColor;
        },
        advisoryUrl() {
            return this.usedAdvisoryMethod === 3 && this.extInfo.advisoryUrl !== '' ? this.extInfo.advisoryUrl : null;
        },
        CREATOR_MINLEN() {
            return this.extInfo.CREATOR_MINLEN;
        },
        memberName() {
            return this.extInfo.memberName;
        },
        // eslint-disable-next-line vue/return-in-computed-property
        mobiTipsStyle() {
            if (this.openThemeV3) {
                return {};
            } else if (this.templateLayoutId != TEMPLATE_LAYOUT.NAV_AUTO && this.colId == COL.CUS) {
                return {
                    top: `1.5rem;`,
                };
            }
        },
        productFormId() {
            return this.extInfo.productFormId;
        },
        collectionClass() {
            return this.isShowCollectionHeadIcon && this.sessionMid != 0 && this.collectionList.includes(this.productId)
                ? ['icon-collectSelect', 'g_main_color_v3']
                : [];
        },
        knowPayVideoStyle() {
            return { 'object-fit': 'scale-down', height: '100%' };
        },
        ellipsisStyle() {
            return {
                'word-break': 'normal',
                'white-space': 'normal',
                overflow: 'hidden',
                'text-overflow': 'ellipsis',
            };
        },
        downloadModel() {
            return this.extInfo.downloadModel || {};
        },
        showFileDownload() {
            return this.downloadModel.allow && this.downloadModel.hasFile && this.downloadModel.open;
        },
        downloadUrl() {
            if (this._manageMode === true) {
                return this.downloadModel.downloadUrl;
            }
            const notAllow =
                this.downloadAuth.isNeedLogin === true || this.downloadAuth.currentMemberPdAttachAllow === false;
            return notAllow ? 'javascript:;' : this.downloadModel.downloadUrl;
        },
        downloadAuth() {
            return this.extInfo.currentPdAttachDownloadAuthForMember ?? {};
        },
    },
    mounted() {
        // 外部脚本提示
        this.useEffect();

        this.initRealNameAuthTips();
    },
    methods: {
        ...mapMutations(['addMobiTips']),
        back() {
            goBack();
        },
        isEmpty(val) {
            return Array.isArray(val) ? val.length === 0 : Object.keys(val).length === 0;
        },
        playVideo() {
            getProductVideo(this.productId);
        },
        quitVideo() {
            videoQuit();
        },
        getTabLiId(id) {
            return `tabLi${id}`;
        },
        getTabLiClass(index) {
            let tmp = 'tabLi';
            if (index === 0) {
                tmp += 'tabActive g_main_color_v3 g_main_bdColor_v3';
            }
            return tmp;
        },
        productKnowPayBtnClickHandler() {
            if (!this.isFinPay) {
                productKnowPayBtnClick(this.productId);
            }
            return false;
        },
        checkKnowPayVideoHandler(productId, finalOpener) {
            checkKnowPayVideo(productId, finalOpener);
        },
        productKnowPayVideoQuit() {
            productKnowPayVideoQuit();
        },
        bookingButtonClickHandler() {
            if (this.productFormOpen) {
                productFormBtnClick(this.productFormId, this.productInfo.id);
            }
        },
        useEffect() {
            if (this.hasThirdCode && this.oem) {
                showTopTips();
            } else if (this.hasThirdCode && !this.oem) {
                showTopTips(3);
            }

            if (!(this.isEmptyProductInfo || (this.notAdded && !(VITE_APP_MODE !== 'visitor')))) {
                setMemberPriceLabelColot2ThemeBackgroundColor();

                Fai.top.choiceCurrencyVal = this.choiceCurrencyValPure;
                Fai.top.finallyShowPrices = this.finallyShowPrices;

                if (this.openKnowPayment) {
                    if (this.isVideoPayProduct) {
                        hideFooterBox();
                    }
                    if (this.isPicTextProduct || this.isColumnProduct) {
                        if (this.isBanCopy) {
                            initPictureSlidesV4('.swipImgBox .proDetailImg', this.optionSlidePicList, {
                                fromCurentInx: true,
                                contentTerminal: 'mobi-产品展示-产品详情',
                            });
                        } else {
                            banCurPageContentCopy();
                        }
                        initProductSwipe(`productNewDetailSwipe${this.moduleId}`);
                    }

                    Fai.top.bssKnowProductType = this.bssKnowProductType;
                } else {
                    initPictureSlidesV4('.swipImgBox .proDetailImg', this.optionSlidePicList, {
                        fromCurentInx: true,
                        contentTerminal: 'mobi-产品展示-产品详情',
                    });
                    if (this.showVideo) {
                        initVideo(this.productId);
                    }
                    if (this.isInitProductSwipe) {
                        initProductSwipe(`productNewDetailSwipe${this.moduleId}`);
                    }
                }

                initPbCommUploadImgParam(
                    this.COMMENT_UP_IMG_MAX_SIZE,
                    this.COMMENT_UP_IMG_TYPE_VALId,
                    this.siteMainDomain
                );

                if (this.isShowCollectionHeadIcon) {
                    new initMbPdCollection(
                        this.sessionMid,
                        this.collectionList,
                        this.productId,
                        VITE_APP_MODE !== 'visitor'
                    ).init();
                }

                if (this.pageStyle === 1) {
                    setPdMallPriceHeight(0.4);
                }

                if (!(this.mallPriceHtml === '') || !(this.mallMarketPriceHtml === '')) {
                    showNewMarketPrice(this.showNewMarketPrice);
                }

                if (this.proSelSignValue === 'style1' || this.proSelSignValue === 'style3') {
                    setMarketingProPdStyle(this.proSelSignValue);
                }

                if (this.showProductGuaranteePanel) {
                    productGuaranteeEventInit();
                }

                if (!this.openKnowPayment) {
                    if (this.immediatelyConsultOpen) {
                        consultImmediatelyBtnClick(
                            'consultImmediatelyBtn',
                            this.usedAdvisoryMethod,
                            this.advisoryPhone,
                            this.wechatImgPath,
                            this.mobiAdvisoryIde
                        );
                    } else {
                        consultImmediatelyBtnClick(
                            'consultImmediatelyBtn',
                            this.usedAdvisoryMethod,
                            this.advisoryPhone,
                            this.wechatImgPath
                        );
                    }
                    if (this.bookingOpen) {
                        bookingPanelClickEvent('J_productBookingBtn', 3);
                    }
                } else {
                    if (this.isBelongToColumnProduct) {
                        initCourseCatalog(this.belongToColumnProductId, this.productId);
                    }
                }

                if (!this.bookingOpen && !this.productFormOpen) {
                    showFooterBox();
                }

                if (this.showOptionList.length) {
                    initProductOptions(this.optionsInfo);
                    initOptionsStr(this.oldOptionsStr);
                }

                if (this.recommendProOpen === 0) {
                    Fai.top._productRecommentData = this.productRecommentData;
                } else {
                    Fai.top._productRecommentData = '';
                }

                if (this.openCommStar) {
                    setStarSelect();
                }

                Fai.top._shareDataProdectDetail = this.shareDataProdectDetail;

                productNewDetailEventInit(this.argsParam);
            }

            jm(window).bind('pageshow', function () {
                jm('#productNum').change();
            });

            mallProductDetailFixedBar();
            allFixWXPA(3, this.moduleId, this.isOpen);
            bindServiceBtnEvent(this.moduleId, false);
            fixPorductDetailStyle(this.moduleId);
            initShowMallOptionImg(this.moduleId);
            initCustab(this.moduleId);
            allFixWXPA(3, this.moduleId, this.wxOpen);

            if (VITE_APP_MODE !== 'visitor') {
                Fai.top._productNewDetailExpData = this.exportData;
            }

            bindImgLazyLoad(`module${this.moduleId}`);
        },

        initRealNameAuthTips() {
            if (VITE_APP_MODE === 'visitor') {
                if (this.realNameAuth.allowShowMessage === false) {
                    this.addMobiTips(MOBILE_AUTH_TIPS);
                }
            }
        },

        downloadClick() {
            let manageMode = window.bizShared.context.isManageMode();
            window.bizShared.context.fdpLog('jz_product_file_click', {
                jz_content_terminal: 'mobi',
                jz_free_text1: manageMode ? '管理态' : '访客态',
                jz_free_text2: '产品详情页',
                jz_version: window.bizShared.context.jzVersion,
            });

            if (!manageMode) {
                if (this.downloadAuth.isNeedLogin === true) {
                    navigateToLoginPage({
                        errno: -3001,
                    });
                    return;
                }
                if (this.downloadAuth.currentMemberPdAttachAllow === false) {
                    ing(format(LS.site_file_down_group_tip, this.memberName));
                    return;
                }
            }
        },
    },
};
</script>
