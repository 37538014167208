var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "userCommentPanel",
      staticStyle: { display: "none" },
      attrs: { id: "userCommentPanel" },
    },
    [
      _c("div", { staticClass: "userCommentHead" }, [
        _c("span", {
          staticClass: "userCommentGoBack icon-gClose g_iconMiddle",
        }),
        _vm._v(_vm._s(_vm.LS.mobi_Post_Comments)),
        _c("a", {
          staticClass: "userCommentGoHome icon-gHome g_iconMiddle",
          attrs: { href: _vm.options.linkLang + "/" },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "userCommentBody" },
        [
          _vm.options.warningTips
            ? _c(
                "div",
                { staticClass: "comment_warning js_newsDetailCommitDisabled" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.options.warningTips) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showTab
            ? [
                _c("ul", { staticClass: "tabUl" }, [
                  _c(
                    "li",
                    {
                      staticClass: "tabLi tabActive",
                      class: _vm.userCommentBodyClass,
                      attrs: { id: "tabUserLi1", data: "1" },
                    },
                    [_vm._v(_vm._s(_vm.LS.mobi_Log_Comments))]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "tabLi",
                      attrs: { id: "tabUserLi2", data: "2" },
                    },
                    [_vm._v(_vm._s(_vm.LS.mobi_Incognito_Comments))]
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tabBody" },
            [
              _c(
                "div",
                {
                  staticClass: "tabPanel tabPanelAtive",
                  attrs: { id: "tabUserPanel1" },
                },
                [
                  _vm.manageMode
                    ? _c("div", { staticClass: "notice" }, [
                        _vm._v("您目前处于网站管理状态，请先退出再评论"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "userCommentInfo" }, [
                    _c("div", { staticClass: "g_globalLine" }, [
                      _c("input", {
                        staticClass: "itemEdit g_input",
                        attrs: {
                          id: "user",
                          disabled: _vm.isLogin,
                          minlength: _vm.isLogin ? _vm.creatorMinlen : null,
                          placeholder:
                            _vm.mobi_member_PleaseInput +
                            _vm.LS.mobi_member_Account,
                          type: "text",
                          maxlength: "50",
                        },
                        domProps: {
                          value: _vm.isLogin ? _vm.memberName : null,
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "icon-mnameIcon" }),
                    ]),
                    _vm._v(" "),
                    !_vm.isLogin
                      ? _c("div", { staticClass: "g_globalLine" }, [
                          _c("input", {
                            staticClass: "itemEdit g_input",
                            attrs: {
                              id: "password",
                              type: "password",
                              onpaste: "return false;",
                              maxlength: "20",
                              placeholder:
                                _vm.mobi_member_PleaseInput +
                                _vm.LS.mobi_member_Password,
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "icon-mpswIcon" }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "userCommentRemarkPanel1" } }, [
                      _c("div", { staticClass: "g_globalLine" }, [
                        _c("textarea", {
                          staticClass: "itemEdit msgIcon g_textArea",
                          attrs: {
                            id: "userCommentRemark",
                            minlength: _vm.commentMinlen,
                            maxlength: _vm.commentMaxlen,
                            placeholder:
                              _vm.mobi_member_PleaseInput +
                              _vm.LS.msgBoardContent,
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "icon-mmsgIcon" }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "userCommentComform g_button",
                        class: _vm.userCommentComformClass,
                        attrs: { id: "userCommentLoginAndComform" },
                      },
                      [_vm._v(_vm._s(_vm.LS.mobi_Submit))]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.showTab
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "tabPanel",
                        attrs: { id: "tabUserPanel2" },
                      },
                      [
                        _vm.manageMode
                          ? _c("div", { staticClass: "notice" }, [
                              _vm._v("您目前处于网站管理状态，请先退出再评论"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "userCommentInfo",
                            attrs: { id: "userCommentInfo2" },
                          },
                          [
                            _c("div", { staticClass: "g_globalLine" }, [
                              _c("input", {
                                staticClass: "itemEdit g_input",
                                attrs: {
                                  id: "anonymityCommentUser",
                                  type: "text",
                                  maxlength: "50",
                                  placeholder:
                                    _vm.mobi_member_PleaseInput +
                                    _vm.LS.productCommentCreator,
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "icon-mnameIcon" }),
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              attrs: { id: "userCommentRemarkPanel2" },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "userCommentComform g_button g_main_bgColor_v3",
                                attrs: { id: "anonymityCommentComform" },
                              },
                              [_vm._v(_vm._s(_vm.LS.mobi_Submit))]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                attrs: { id: "postCommentId", type: "hidden" },
                domProps: { value: _vm.newsId },
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }