import { dayjs } from '@jz/utils';
export async function getProtocols(memberId) {
    // 用 jzRequest 来获取协议列表信息，目前先模拟数据
    let result = await window.jzRequest.get('/ajax/member_h.jsp', {
        params: {
            cmd: 'getWafNotCk_getProtocolList',
            id: memberId,
        },
    });

    let list = [];

    if (result.data.success) {
        list = result.data.data.map((item) => {
            item.time = dayjs(item.time).format('YYYY-MM-DD HH:mm:ss');
            return item;
        });
    } else {
        console.error(result.data.msg);
    }

    return list;
}
