var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _vm.isEmptyProductInfo || (_vm.notAdded && !_vm._manageMode)
      ? _c(
          "div",
          {
            staticClass: "productNewDetailPanel J_pd_detail",
            class: _vm.productDetailVersionClass,
            attrs: {
              id: _vm.productNewDetailPanelId,
              "data-content-type": _vm.knowPayConentTypeProperty,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "productDetailHeader detailHeaderColor",
                staticStyle: { position: "relative" },
                style: _vm.mobiTipsStyle,
                attrs: { id: _vm.productDetailHeaderId },
              },
              [
                _c("a", {
                  staticClass:
                    "icon-gClose g_round g_iconMiddle proBack icon-defaultColor",
                  on: { click: _vm.back },
                }),
                _vm._v(" "),
                _c("a", {
                  staticClass:
                    "icon-gHome g_round g_iconMiddle proOther icon-defaultColor",
                  attrs: { href: _vm.linkLang },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "productNotAdded productNotAdded-icon" }),
            _vm._v(" "),
            _c("div", { staticClass: "productNotAdded-tip" }, [
              _vm._v(_vm._s(_vm.productNotAdded)),
            ]),
          ]
        )
      : _c(
          "div",
          {
            class: _vm.productNewDetailPanelClass,
            attrs: {
              id: _vm.productNewDetailPanelId,
              "data-content-type": _vm.knowPayConentTypeProperty,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "productDetailHeader detailHeaderColor",
                staticStyle: { background: "rgba(255, 255, 255, 0.992157)" },
                style: _vm.mobiTipsStyle,
                attrs: { id: _vm.productDetailHeaderId },
              },
              [
                _c("span", {
                  staticClass:
                    "icon-gClose g_round g_iconMiddle proBack icon-defaultColor",
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "icon-gOther g_round g_iconMiddle proOther icon-defaultColor",
                }),
                _vm._v(" "),
                _c("div", { staticClass: "proOtherPanel" }, [
                  _c("a", {
                    staticClass:
                      "icon-gHome g_iconMiddle g_round proHome icon-defaultColor",
                    attrs: { href: _vm.linkLang + "/index.jsp" },
                  }),
                  _vm._v(" "),
                  _vm.openShare === 0
                    ? _c("span", {
                        staticClass:
                          "icon-share g_iconMiddle g_round proShare icon-defaultColor",
                        attrs: { id: "productDetailShare" },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.productDetail_v3
                  ? _c(
                      "div",
                      {
                        staticClass: "productDetailV3_title",
                        staticStyle: { display: "none" },
                        attrs: { id: _vm.productDetailV3_titleId },
                      },
                      [_vm._v(_vm._s(_vm.productName))]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "productNewDetail",
                attrs: { showSaleNoEnd: _vm.isNoEndTime },
              },
              [
                _c("div", { staticStyle: { position: "relative" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "proImgSwipe",
                      attrs: { id: _vm.productNewDetailSwipeId },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "proDetailImgBox",
                          style: _vm.proDetailImgBoxStyle,
                        },
                        [
                          _vm.openKnowPayment
                            ? [
                                _vm.isVideoPayProduct
                                  ? _c(
                                      "div",
                                      { staticClass: "knowPayProduct" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "proDetailImg J_img_lazyload",
                                            class: _vm.imgZoomClass,
                                            style: _vm.proDetailImgStyle,
                                            attrs: {
                                              "src-original": _vm.picPath,
                                            },
                                          },
                                          [
                                            _vm.isFinPay
                                              ? [
                                                  _c("div", {
                                                    staticClass:
                                                      "videoPlay faisco-icons-",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.checkKnowPayVideoHandler(
                                                          _vm.productId
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "none",
                                                      },
                                                      attrs: {
                                                        id: "knowproductVideoMask",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mobiProductVideoDialog productKnowPayVideo",
                                                        },
                                                        [
                                                          _c("video", {
                                                            staticClass:
                                                              "mobiVideo",
                                                            style:
                                                              _vm.knowPayVideoStyle,
                                                            attrs: {
                                                              id: "knowPayVideo",
                                                              controls: "",
                                                              "webkit-playsinline":
                                                                "true",
                                                              playsinline:
                                                                "true",
                                                              controlsList:
                                                                "nodownload",
                                                              preload: "auto",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "videoQuit",
                                                          on: {
                                                            click:
                                                              _vm.productKnowPayVideoQuit,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "videoQuit-close",
                                                            },
                                                            [
                                                              _c("use", {
                                                                attrs: {
                                                                  href: "#jzm-vicon_close",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : [
                                                  _vm.finalOpener
                                                    ? [
                                                        _c("div", {
                                                          staticClass:
                                                            "videoPlay faisco-icons-",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.checkKnowPayVideoHandler(
                                                                _vm.productId,
                                                                _vm.finalOpener
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "videoTryTipContainer",
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "videoTryBg",
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "videoTryTip",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "可试看前" +
                                                                      _vm._s(
                                                                        _vm.videoTryMin
                                                                      ) +
                                                                      "分钟，购买后可收看完整内容"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "none",
                                                            },
                                                            attrs: {
                                                              id: "knowproductVideoMask",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mobiProductVideoDialog productKnowPayVideo",
                                                              },
                                                              [
                                                                _c("video", {
                                                                  staticClass:
                                                                    "mobiVideo",
                                                                  style:
                                                                    _vm.knowPayVideoStyle,
                                                                  attrs: {
                                                                    id: "knowPayVideo",
                                                                    controls:
                                                                      "",
                                                                    "webkit-playsinline":
                                                                      "true",
                                                                    playsinline:
                                                                      "true",
                                                                    controlsList:
                                                                      "nodownload",
                                                                    preload:
                                                                      "auto",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "videoQuit",
                                                                on: {
                                                                  click:
                                                                    _vm.productKnowPayVideoQuit,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "videoQuit-close",
                                                                  },
                                                                  [
                                                                    _c("use", {
                                                                      attrs: {
                                                                        href: "#jzm-vicon_close",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c("div", {
                                                          staticClass:
                                                            "videoPlay faisco-icons-",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.checkKnowPayVideoHandler(
                                                                _vm.productId
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "none",
                                                            },
                                                            attrs: {
                                                              id: "knowproductVideoMask",
                                                            },
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "mobiProductVideoDialog productKnowPayVideo",
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "videoQuit",
                                                                on: {
                                                                  click:
                                                                    _vm.productKnowPayVideoQuit,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "videoQuit-close",
                                                                  },
                                                                  [
                                                                    _c("use", {
                                                                      attrs: {
                                                                        href: "#jzm-vicon_close",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm.isPicTextProduct || _vm.isColumnProduct
                                  ? [
                                      _vm.picPathList.length !== 0
                                        ? _vm._l(
                                            _vm.picPathList,
                                            function (pic) {
                                              return _c(
                                                "div",
                                                { staticClass: "swipImgBox" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "proDetailImg J_img_lazyload",
                                                    class: _vm.imgZoomClass,
                                                    style:
                                                      _vm.proDetailImgStyle,
                                                    attrs: {
                                                      vwidth: pic.width,
                                                      vheight: pic.height,
                                                      alt: _vm.productName,
                                                      "data-picId": pic.id,
                                                      "src-original": pic.path,
                                                    },
                                                  }),
                                                ]
                                              )
                                            }
                                          )
                                        : [
                                            _c("div", [
                                              _c("div", {
                                                staticClass:
                                                  "proDetailImg J_img_lazyload",
                                                class: _vm.imgZoomClass,
                                                style: _vm.proDetailImgStyle,
                                                attrs: {
                                                  "src-original":
                                                    _vm.defaultPath,
                                                },
                                              }),
                                            ]),
                                          ],
                                    ]
                                  : _vm.isAudioProduct
                                  ? _c(
                                      "div",
                                      { attrs: { id: "productAudioPlayer" } },
                                      [
                                        _c("product-audio-player", {
                                          attrs: {
                                            "pic-path": _vm.productAudioPicPath,
                                            "is-default-color-style":
                                              _vm.isDefaultColorStyle,
                                            "audio-try-min": _vm.audioTryMin,
                                            "follow-color": _vm.followColor,
                                            "img-zoom-class": _vm.imgZoomClass,
                                            "is-fin-pay": _vm.isFinPay,
                                            "istry-open": _vm.istryOpen,
                                            "is-use-custom-color":
                                              _vm.isUseCustomColor,
                                            "is-v1": _vm.isV1,
                                            "product-id": _vm.productId,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : [
                                _vm.picPathList.length !== 0
                                  ? _vm._l(
                                      _vm.picPathList,
                                      function (pic, index) {
                                        return _c(
                                          "div",
                                          { staticClass: "swipImgBox" },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "proDetailImg J_img_lazyload",
                                              class: _vm.imgZoomClass,
                                              style: _vm.proDetailImgStyle,
                                              attrs: {
                                                vwidth: pic.width,
                                                vheight: pic.height,
                                                alt: _vm.productName,
                                                "src-original": pic.newPath,
                                                "data-picId": pic.id,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _vm.showVideo && index === 0
                                              ? [
                                                  _c("div", {
                                                    staticClass:
                                                      "videoPlay faisco-icons-",
                                                    on: {
                                                      click: _vm.playVideo,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "productVideoMask",
                                                      staticStyle: {
                                                        display: "none",
                                                      },
                                                      attrs: {
                                                        id: "productVideoMask",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mobiProductVideoDialog",
                                                        },
                                                        [
                                                          _c(
                                                            "video",
                                                            {
                                                              staticClass:
                                                                "mobiVideo",
                                                              style:
                                                                _vm.knowPayVideoStyle,
                                                              attrs: {
                                                                id: "productVideo",
                                                                controls: "",
                                                              },
                                                            },
                                                            [
                                                              _c("source", {
                                                                attrs: {
                                                                  src: _vm.flvPagePath,
                                                                  type: "video/mp4",
                                                                },
                                                              }),
                                                              _vm._v(
                                                                "抱歉，您的浏览器不能查看该视频。"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "videoQuit",
                                                          on: {
                                                            click:
                                                              _vm.quitVideo,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "videoQuit-close",
                                                            },
                                                            [
                                                              _c("use", {
                                                                attrs: {
                                                                  href: "#jzm-vicon_close",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    )
                                  : [
                                      _c("div", [
                                        _c("div", {
                                          staticClass:
                                            "proDetailImg J_img_lazyload",
                                          class: _vm.imgZoomClass,
                                          style: _vm.proDetailImgStyle,
                                          attrs: {
                                            "src-original": _vm.defaultPath,
                                          },
                                        }),
                                      ]),
                                    ],
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      !_vm.openKnowPayment &&
                      !_vm.isNewThemeAndPageStyleHavePicture
                        ? _c(
                            "div",
                            {
                              staticClass: "productNameSwipBox",
                              style: _vm.productNameSwipBoxStyle,
                            },
                            [
                              _c(
                                "h1",
                                {
                                  staticClass: "g_displayClamp2",
                                  style: _vm.ellipsisStyle,
                                },
                                [_vm._v(_vm._s(_vm.productName))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.openKnowPayment &&
                      _vm.picPathList.length > 1 &&
                      _vm.pageStyle != 1
                        ? _c(
                            "div",
                            {
                              staticClass: "productBullet",
                              attrs: { id: "productBullet" },
                            },
                            [
                              _c(
                                "ul",
                                {
                                  staticClass: "bullets",
                                  attrs: { id: "bullets" },
                                },
                                _vm._l(_vm.picPathList, function (item, index) {
                                  return _c("li", {
                                    class: { on: index === 0 },
                                  })
                                }),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mallThemeProductWrap" }, [
                  _c(
                    "h1",
                    {
                      staticClass: "productNameText",
                      staticStyle: {
                        "word-break": "normal",
                        "word-wrap": "break-word",
                      },
                    },
                    [_vm._v(_vm._s(_vm.productName))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mallProductToolBtns" }, [
                    _vm.openShare === 0
                      ? _c(
                          "div",
                          {
                            staticClass: "mallShareIcon",
                            attrs: { id: "mallThemeProductDetailShare" },
                          },
                          [
                            _c("div", {
                              staticClass:
                                "icon-share g_iconMiddle proShare icon-defaultColor",
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "shareText" }, [
                              _vm._v(_vm._s(_vm.share)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shareHtml
                      ? _c(
                          "div",
                          {
                            staticClass: "mallCollectionIcon shareBlock",
                            class: _vm.shareTopClass,
                          },
                          [
                            _c("div", {
                              staticClass: "icon-collection",
                              class: _vm.collectionClass,
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "collectText" }, [
                              _vm._v(_vm._s(_vm.collect)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "pd3ProductNameContent",
                    class: { pd3NoCollection: _vm.shareHtml === "" },
                    staticStyle: { position: "relative" },
                    style: _vm.pd3ProductNameContentStyle,
                  },
                  [
                    _c(
                      "h1",
                      {
                        staticClass: "pd3ProductName",
                        style: _vm.ellipsisStyle,
                      },
                      [_vm._v(_vm._s(_vm.productName))]
                    ),
                    _vm._v(" "),
                    _vm.shareHtml
                      ? _c(
                          "div",
                          {
                            staticClass: "mallCollectionIcon shareBlock",
                            class: _vm.shareTopClass,
                          },
                          [
                            _c("div", {
                              staticClass: "icon-collection",
                              class: _vm.collectionClass,
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "collectText" }, [
                              _vm._v(_vm._s(_vm.collect)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.mallPriceHtml !== "" ||
                _vm.mallMarketPriceHtml !== "" ||
                _vm.shareHtml !== "" ||
                (_vm.isNewTheme && _vm.isBeginTimeGroundDown)
                  ? _c(
                      "div",
                      {
                        class: _vm.productPriceClass,
                        style: _vm.productPriceStyle,
                      },
                      [
                        _vm.isKnowPayProduct &&
                        _vm.openKnowPayment &&
                        (_vm.isMallTheme ||
                          (_vm.productDetail_v3 == "" &&
                            _vm.newCountDownProductName == ""))
                          ? _c(
                              "div",
                              { staticClass: "newCountDownProductName" },
                              [
                                _c(
                                  "h1",
                                  {
                                    staticClass: "pd3ProductName",
                                    style: _vm.ellipsisStyle,
                                  },
                                  [_vm._v(_vm._s(_vm.productName))]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.mallPriceHtml === "" &&
                        _vm.mallMarketPriceHtml === ""
                          ? _c("span", {
                              staticClass: "mallPrice hiddenMallPrice",
                              style:
                                _vm.pageStyle == 1
                                  ? "height:1.8rem;line-height:1.8rem;"
                                  : "",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isLoadOldCountDownInManageOrOldTheme
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.mallPriceHtml),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.showNewMarketPrice
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.mallMarketPriceHtml),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.productDetail_v3 === "" && _vm.shareHtml
                          ? _c(
                              "div",
                              {
                                staticClass: "mallCollectionIcon shareBlock",
                                class: _vm.shareTopClass,
                              },
                              [
                                _c("div", {
                                  staticClass: "icon-collection",
                                  class: _vm.collectionClass,
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "collectText" }, [
                                  _vm._v(_vm._s(_vm.collect)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showNewMarketPrice &&
                        _vm.isLoadOldCountDownInManageOrOldTheme
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.mallMarketPriceHtml),
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showProductGuaranteePanel
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "productGuarantee J_guarantee",
                          style: _vm.guaranteeContentStyle,
                          attrs: { id: "guaranteeContent" },
                        },
                        [
                          _vm._l(_vm.guaranteeList, function (guarantee) {
                            return guarantee.show
                              ? _c("div", { staticClass: "guaranteeName" }, [
                                  _c("i", {
                                    staticClass: "icon-guarantee g_mainColor",
                                  }),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(guarantee.name) +
                                      "\n                    "
                                  ),
                                ])
                              : _vm._e()
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "faisco-icons-forward1 g_middleColor more",
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "productGuaranteePanel",
                          staticStyle: { display: "none" },
                          attrs: { id: "productGuaranteePanel" },
                        },
                        [
                          _c("div", {
                            staticStyle: { height: "100%" },
                            attrs: { id: "closeProductGuarantee" },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "productGuaranteeContentPanel" },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("产品保证"),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.guaranteeList, function (guarantee) {
                                return guarantee.show
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "guaranteeName" },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon-guarantee g_mainColor",
                                          }),
                                          _vm._v(_vm._s(guarantee.name)),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      guarantee.e
                                        ? _c("div", {
                                            staticClass: "guaranteeContent",
                                            domProps: {
                                              innerHTML: _vm._s(guarantee.e),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : _vm._e()
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "confirmBtn g_bgColor",
                                  style: _vm.guaranteeBtnStyle,
                                  attrs: { id: "guaranteeBtn" },
                                },
                                [_vm._v("我知道了")]
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "mallThemeSeparator g_breakline" }),
                _vm._v(" "),
                _vm.mallOptionsOpen
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "proCommProperty productInfoSize borderClass",
                        attrs: { id: "productInfoSize" },
                      },
                      [
                        _c("span", {
                          staticClass:
                            "productParamterNameList pdInfoTitle g_middleColor",
                          domProps: { textContent: _vm._s(_vm.LS.select) },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "productParamterWords" }, [
                          _vm._v(_vm._s(_vm.productParamterNameList)),
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "choiceProductSize icon-gGoforward g_iconMiddle g_middleColor",
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "mallThemeSeparator g_breakline" }),
                _vm._v(" "),
                _vm.showFileDownload
                  ? _c("div", { staticClass: "detail_file_download" }, [
                      _c("span", {
                        staticClass: "label",
                        domProps: {
                          textContent: _vm._s(
                            _vm.downloadModel.downloadBtnText
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "filename",
                        domProps: {
                          textContent: _vm._s(_vm.downloadModel.fileName),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "file_download_link",
                          attrs: { href: _vm.downloadUrl },
                          on: { click: _vm.downloadClick },
                        },
                        [
                          _c("svg", { staticClass: "download" }, [
                            _c("use", { attrs: { href: "#download" } }),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.mallOptionsOpen
                  ? _c("div", { staticClass: "mallThemeSeparator g_breakline" })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "productDetailTabPanel",
                    class: _vm.btnSpaceClass,
                    style: _vm.btnSpaceStyle,
                    attrs: { id: _vm.productDetailTabPanelClass },
                  },
                  [
                    _c(
                      "ul",
                      {
                        staticClass: "tabUl",
                        style: _vm.mobiTipsStyle,
                        attrs: { id: "productTabUl" },
                      },
                      [
                        _c("div", { staticClass: "tabUiDiv" }, [
                          _c("div", {
                            staticClass: "tabUiDiv2",
                            style: _vm.tabLiDivStyle,
                            domProps: { innerHTML: _vm._s(_vm.tabHeadHtml) },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("html-render", {
                      ref: "tabBody",
                      staticClass: "tabBody",
                      attrs: { html: _vm.tabBodyHtml },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.bookingOpen ||
                _vm.productFormOpen ||
                _vm.immediatelyConsultOpen ||
                _vm.openKnowPayment
                  ? _c("div", { staticClass: "fixedWrap" }, [
                      _c(
                        "div",
                        {
                          staticClass: "productMallShop",
                          class: _vm.fixedWrapClass,
                        },
                        [
                          _vm.openKnowPayment
                            ? [
                                _vm.isBelongToColumnProduct
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mallThemeAddCartBtn productAddMall g_mallButton",
                                        attrs: { id: "J_courseCatalogBtn" },
                                      },
                                      [_vm._v("课程目录")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "bookingAddCartBtn productImmediatelyBug g_bookingButton J_productBookingBtn",
                                    class: _vm.productKnowPayBtnClass,
                                    style: _vm.isFinPay ? "opacity: 0.5" : "",
                                    attrs: { id: "J_productKnowPayBtn" },
                                    on: {
                                      click: _vm.productKnowPayBtnClickHandler,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.knowPaymentText) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _vm.immediatelyConsultOpen
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "consultImmediatelyBtn productImmediatelyBug g_bookingButton g_conSultBtn",
                                        class: _vm.consultImmediatelyBtnClass,
                                        attrs: {
                                          id: "consultImmediatelyBtn",
                                          href: _vm.advisoryUrl,
                                          _pid: _vm.productId,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.advisoryTxt) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.bookingOpen || _vm.productFormOpen
                                  ? _c("div", {
                                      staticClass:
                                        "bookingAddCartBtn productImmediatelyBug g_bookingButton J_productBookingBtn",
                                      class: _vm.bookingAddCartBtnClass,
                                      attrs: {
                                        id: "J_productBookingBtn",
                                        _pid: _vm.productId,
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(_vm.shopBtnTypeText),
                                      },
                                      on: {
                                        click: _vm.bookingButtonClickHandler,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                        ],
                        2
                      ),
                    ])
                  : _vm.openService === 0 && _vm._manageMode
                  ? _c(
                      "div",
                      {
                        staticClass: "serviceBtnOuter productMallShop",
                        class: _vm.foreignClass,
                        attrs: { id: "serviceBtnOuter" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "serviceBtn noMallServiceBtn",
                            attrs: { id: "serviceBtn" },
                          },
                          [
                            _vm._v('"\n                    '),
                            _c("div", { staticClass: "serviceInner" }, [
                              _c("div", {
                                staticClass: "icon-serviceBtn serviceBtnIcon",
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "serviceBtnText" }, [
                                _vm._v(_vm._s(_vm.mobiService)),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "userCommentPanel",
                staticStyle: { display: "none" },
                attrs: { id: "userCommentPanel" },
              },
              [
                _c("div", { staticClass: "userCommentHead" }, [
                  _c("span", {
                    staticClass: "userCommentGoBack icon-gClose g_iconMiddle",
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.mobiPostComments) +
                      "\n                "
                  ),
                  _c("a", {
                    staticClass: "userCommentGoHome icon-gHome g_iconMiddle",
                    attrs: { href: _vm.linkLang },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "userCommentBody" }, [
                  !_vm.isOnlyMember
                    ? _c("ul", { staticClass: "tabUl" }, [
                        _c(
                          "li",
                          {
                            staticClass: "tabLi tabActive",
                            attrs: { id: "tabUserLi1", data: "1" },
                          },
                          [_vm._v(_vm._s(_vm.mobiLogComments))]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass: "tabLi",
                            attrs: { id: "tabUserLi2", data: "2" },
                          },
                          [_vm._v(_vm._s(_vm.mobiIncognitoComments))]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "tabBody" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tabPanel tabPanelAtive",
                        attrs: { id: "tabUserPanel1" },
                      },
                      [
                        _vm._manageMode
                          ? _c("div", { staticClass: "notice" }, [
                              _vm._v("您目前处于网站管理状态，请先退出再评论"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "userCommentInfo" }, [
                          _c("div", { staticClass: "g_globalLine" }, [
                            _vm._sessionMemberId <= 0
                              ? _c("input", {
                                  staticClass: "itemEdit g_input",
                                  attrs: {
                                    id: "user",
                                    type: "text",
                                    maxlength: "50",
                                    placeholder:
                                      _vm.mobiMemberPleaseInput +
                                      _vm.mobiMemberAccount,
                                  },
                                })
                              : _c("input", {
                                  staticClass: "itemEdit g_input",
                                  attrs: {
                                    id: "user",
                                    type: "text",
                                    maxlength: "50",
                                    disabled: "true",
                                    minlength: _vm.CREATOR_MINLEN,
                                    placeholder:
                                      _vm.mobiMemberPleaseInput +
                                      _vm.mobiMemberAccount,
                                  },
                                  domProps: { value: _vm.memberName },
                                }),
                            _vm._v(" "),
                            _c("span", { staticClass: "icon-mnameIcon" }),
                          ]),
                          _vm._v(" "),
                          _vm._sessionMemberId <= 0
                            ? _c("div", { staticClass: "g_globalLine" }, [
                                _c("input", {
                                  staticClass: "itemEdit g_input",
                                  attrs: {
                                    id: "password",
                                    type: "password",
                                    onpaste: "return false;",
                                    autocomplete: "off",
                                    maxlength: "20",
                                    placeholder:
                                      _vm.mobiMemberPleaseInput +
                                      _vm.mobiMemberPassword,
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "icon-mpswIcon" }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.openCommStar
                            ? _c(
                                "div",
                                {
                                  staticClass: "l-star-list J-star-list",
                                  attrs: { _star: "5" },
                                },
                                [
                                  _c("li", {
                                    staticClass: "faisco-icons-star3",
                                  }),
                                  _vm._v(" "),
                                  _c("li", {
                                    staticClass: "faisco-icons-star3",
                                  }),
                                  _vm._v(" "),
                                  _c("li", {
                                    staticClass: "faisco-icons-star3",
                                  }),
                                  _vm._v(" "),
                                  _c("li", {
                                    staticClass: "faisco-icons-star3",
                                  }),
                                  _vm._v(" "),
                                  _c("li", {
                                    staticClass: "faisco-icons-star3",
                                  }),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "score-tip" }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { id: "userCommentRemarkPanel1" } },
                            [
                              _c("div", { staticClass: "g_globalLine" }, [
                                _c("textarea", {
                                  staticClass:
                                    "itemEdit msgIcon g_textArea icon-msgIcon",
                                  attrs: {
                                    id: "userCommentRemark",
                                    minlength: _vm.COMMENT_MINLEN,
                                    maxlength: _vm.COMMENT_MAXLEN,
                                    placeholder:
                                      _vm.mobiMemberPleaseInput +
                                      _vm.msgBoardContent,
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "icon-mmsgIcon" }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "g_globalLine J_productCommImg upImgSet",
                                },
                                [
                                  _c(
                                    "table",
                                    {
                                      attrs: {
                                        id: "pt_add_img_tb",
                                        cellpadding: "0",
                                        cellspacing: "0",
                                      },
                                    },
                                    [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "comm-find-tb",
                                              attrs: {
                                                id: "pt_add_img_tb",
                                                maxNum:
                                                  _vm.COMMENT_UP_IMG_MAX_NUM,
                                              },
                                            },
                                            [
                                              _c("div", {
                                                attrs: {
                                                  id: "comm-img-swfu-placeholder",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "userCommentComform g_button",
                              attrs: { id: "userCommentLoginAndComform" },
                            },
                            [_vm._v(_vm._s(_vm.mobiSubmit))]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.isOnlyMember
                      ? _c(
                          "div",
                          {
                            staticClass: "tabPanel",
                            attrs: { id: "tabUserPanel2" },
                          },
                          [
                            _vm._manageMode
                              ? _c("div", { staticClass: "notice" }, [
                                  _vm._v(
                                    "您目前处于网站管理状态，请先退出再评论"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "userCommentInfo",
                                attrs: { id: "userCommentInfo2" },
                              },
                              [
                                _c("div", { staticClass: "g_globalLine" }, [
                                  _c("input", {
                                    staticClass: "itemEdit g_input",
                                    attrs: {
                                      id: "anonymityCommentUser",
                                      type: "text",
                                      maxlength: "50",
                                      placeholder:
                                        _vm.mobiMemberPleaseInput +
                                        _vm.productCommentCreator,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "icon-mnameIcon" }),
                                ]),
                                _vm._v(" "),
                                _vm.openCommStar
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "l-star-list J-star-list",
                                        attrs: { _star: "5" },
                                      },
                                      [
                                        _c("li", {
                                          staticClass: "faisco-icons-star3",
                                        }),
                                        _vm._v(" "),
                                        _c("li", {
                                          staticClass: "faisco-icons-star3",
                                        }),
                                        _vm._v(" "),
                                        _c("li", {
                                          staticClass: "faisco-icons-star3",
                                        }),
                                        _vm._v(" "),
                                        _c("li", {
                                          staticClass: "faisco-icons-star3",
                                        }),
                                        _vm._v(" "),
                                        _c("li", {
                                          staticClass: "faisco-icons-star3",
                                        }),
                                        _vm._v(" "),
                                        _c("li", { staticClass: "score-tip" }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", {
                                  attrs: { id: "userCommentRemarkPanel2" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "userCommentComform g_button g_main_bgColor_v3",
                                    attrs: { id: "anonymityCommentComform" },
                                  },
                                  [_vm._v(_vm._s(_vm.mobiSubmit))]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { id: "postCommentId", type: "hidden" },
                      domProps: { value: _vm.productId },
                    }),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "productMallOptionPanel",
                class: _vm.productDetail_v3,
                staticStyle: { display: "none" },
                attrs: { id: "productMallOptionPanel" },
              },
              [
                _c("div", { staticClass: "productMallOptionContentPanel" }, [
                  _c(
                    "div",
                    {
                      staticClass: "productMallOptionContent",
                      staticStyle: {
                        bottom: "0rem",
                        "padding-bottom": "0.3rem",
                      },
                    },
                    [
                      _c("div", { staticClass: "productMallOptionHead" }, [
                        _c("div", { staticClass: "productMallOptionImage" }, [
                          _c(
                            "div",
                            { staticClass: "productMallOptionImageContent" },
                            [
                              _c("span", { staticClass: "imageMiddleSpan" }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "J_mallOptionImg mallOptionImg",
                                attrs: {
                                  picId: _vm.firstPicId,
                                  isOptImg: "false",
                                  src: _vm.firstNewPath,
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "productDetail" }, [
                        _c("div", { staticClass: "productDetailName" }, [
                          _c(
                            "div",
                            { staticClass: "productName g_middleColor" },
                            [
                              _c("div", { staticClass: "productNameWrap" }, [
                                _vm._v(_vm._s(_vm.productName)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.mallPriceHtml2
                            ? _c(
                                "div",
                                {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.mallPriceHtml2),
                                  },
                                },
                                [_vm._v(" >")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "productDetailClose icon-Off g_iconMiddle icon-defaultColor",
                      }),
                      _vm._v(" "),
                      _vm.showOptionList.length
                        ? _c(
                            "div",
                            {
                              staticClass: "mallOptionAndNumBox",
                              attrs: { id: "mallOptionAndNumContainer" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { id: "mallOptionAndNumBox" } },
                                [
                                  _c("div", {
                                    staticClass:
                                      "mallNewOptionBox mallOptionBox J-op-box",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.mallNewOptionBoxHtml
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.optionPicList.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "proImgSwipe",
                                          attrs: {
                                            id: _vm.productDetailOptSwipeId,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "proDetailImgBox",
                                              staticStyle: { display: "none" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "swipImgBox" },
                                                _vm._l(
                                                  _vm.optionPicList,
                                                  function (optionPic) {
                                                    return _c("div", {
                                                      staticClass:
                                                        "proDetailImg",
                                                      class: _vm.imgZoomClass,
                                                      style:
                                                        _vm.proDetailImgStyle,
                                                      attrs: {
                                                        vwidth: optionPic.width,
                                                        vheight:
                                                          optionPic.height,
                                                        alt: _vm.productName,
                                                        "data-picId":
                                                          optionPic.picId,
                                                        "src-original":
                                                          optionPic.newPath,
                                                      },
                                                    })
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "g_separator separatorLine",
                                    staticStyle: { margin: "0 0.75rem" },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }