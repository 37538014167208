<template>
    <div class="jz-profile-visitor">
        <div class="member_basic_info_area g_main_bgColor_v3">
            <div class="member_basic_info member_basic_info_v3">
                <div class="member_msg_head_pic_v3 icon-pnameIcon">
                    <img id="memberHeadPicV3" class="memberHeadPicV3 J_memberJumpToMsg" />
                </div>
                <div class="member_name_and_level" :class="{ not_auth: !memberLevelAuthInfo.allow }">
                    <span class="memberName" v-text="memberName"></span>
                    <span v-if="memberLevelAuthInfo.allow" class="memberLevelName">[ {{ memberLvName }} ]</span>
                </div>
            </div>
        </div>
        <ul class="modifyArea">
            <li v-if="isOpenKnowPayment" id="knowPayment">
                <div class="itemName">
                    <svg><use xlink:href="#jzm-vicon_mall" /></svg><span v-text="LS.minePayKnowProducts"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="integralOpen" id="integral">
                <div class="itemName">
                    <span class="newProfileIcon icon-integralIcon"></span
                    ><span v-text="LS.integral_mine + itgName"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <template v-if="isCrownVer">
                <li v-if="openCollection" id="collection">
                    <div class="itemName">
                        <span class="newProfileIcon icon-starIcon"></span><span v-text="LS.my_collection"></span>
                    </div>
                    <div class="clickIcon">
                        <span class="icon-clickIcon"></span>
                    </div>
                </li>
                <li v-if="openCoupon" id="coupon">
                    <div class="itemName">
                        <span class="newProfileIcon icon-coupons"></span
                        ><span v-text="LS.integral_mine + LS.coupons"></span>
                    </div>
                    <div class="clickIcon">
                        <span class="icon-clickIcon"></span>
                    </div>
                </li>
            </template>
            <li v-if="isOpenMyForm" id="myForm" :_noFormRecord="LS.no_form_record">
                <div class="itemName">
                    <svg><use xlink:href="#jzm-v27" /></svg><span v-text="LS.mobi_member_myForm"></span>
                    <div :class="formHiddenClass"></div>
                </div>
                <div class="clickIcon">
                    <span class="update_tips" :class="{ hidden: hidden != '' }" v-text="updateTips"></span>
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="memberBulletinOpen" id="latestNews" orderlist="1">
                <div class="itemName">
                    <span class="newProfileIcon icon-noticeIcon"></span><span v-text="bulletinTitle"></span>
                    <div id="bulletinTitle" :class="bulletinTitleClass" :sessionMid="sessionMid"></div>
                </div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <li v-if="isCn && hasPrize && isOpenLottery" id="myreward">
                <div class="itemName">
                    <span class="icon-pInfoIcon"></span><span v-text="LS.mobi_member_MyReward"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li id="personalInfo">
                <div class="itemName">
                    <span class="newProfileIcon icon-pInfoIcon"></span
                    ><span v-text="LS.mobi_membert_PersonalInfo"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li id="password">
                <div class="itemName">
                    <span class="newProfileIcon icon-cPswIcon"></span
                    ><span v-text="LS.mobi_membert_ResetPassword"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="isOtherLogin" id="changeOtherLoginAcct">
                <div class="itemName">
                    <span class="newProfileIcon icon-bangding" v-text="LS.mobi_membert_ChangeOtherLoginAcct"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="protocolList.length" id="memberProtocol">
                <div class="itemName">
                    <svg><use xlink:href="#agreement" /></svg>
                    <span v-text="LS.websiteAgreement || '网站协议'"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="clientDelMember" id="clientDelMember">
                <div class="itemName"
                    ><svg><use xlink:href="#logoff" /></svg><span v-text="LS.clientDel || '注销账号'"></span
                ></div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
        </ul>
        <div class="onLogout" @click="logoutClick">{{ LS.mobi_member_onLogout }}</div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { MemberDef, MemberPropDef } from '../../../memberDef';
import { SiteVerDef } from '@/def/ver';
import { profileLogoutV3 } from '../../method';
import { checkBit } from '@/shared/fai';

export default {
    name: 'JZProfileVisitor',
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
        protocolList: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['LS', 'lcid', '_ver']),
        isCn() {
            return this.lcid == 2052;
        },
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        integralOpen() {
            return this.renderOptions.integralOpen || false;
        },
        isOpenKnowPayment() {
            return this.renderOptions.isOpenKnowPayment || false;
        },
        openCollection() {
            return this.renderOptions.openCollection || false;
        },
        isOpenMyForm() {
            return this.renderOptions.isOpenMyForm || false;
        },
        hasPrize() {
            return this.renderOptions.hasPrize || false;
        },
        isOpenLottery() {
            return this.renderOptions.isOpenLottery || false;
        },
        isOtherLogin() {
            return this.renderOptions.isOtherLogin || '';
        },
        siteResRoot() {
            return this.renderOptions.siteResRoot || '';
        },
        sessionMid() {
            return this.renderOptions.sessionMid || 0;
        },
        memberBulletinOpen() {
            return this.renderOptions.memberBulletinOpen || false;
        },
        itgName() {
            return this.renderOptions.itgName || '';
        },
        openCoupon() {
            return this.renderOptions.openCoupon || false;
        },
        hidden() {
            return this.renderOptions.hidden || '';
        },
        memberLvName() {
            return this.memberLevelInfo.name || '';
        },
        memberLevelInfo() {
            return this.renderOptions.memberLevelInfo || {};
        },
        bulletinTitle() {
            return this.renderOptions.bulletinTitle != ''
                ? this.renderOptions.bulletinTitle
                : this.LS.memberBulletinTitle;
        },
        news_reminds_v3() {
            return this.renderOptions.news_reminds_v3 || '';
        },
        memberInfo() {
            return this.renderOptions.memberInfo || {};
        },
        updateTips() {
            return this.renderOptions.updateTips || '';
        },
        displayList() {
            return this.renderOptions.displayList || [];
        },
        nameProp() {
            let namPropList = this.displayList.filter((item) => {
                return item[MemberPropDef.Info.TYPE] == MemberPropDef.Type.SYS_NAME;
            });
            if (namPropList.length) {
                return namPropList[0];
            } else {
                return null;
            }
        },
        showMemberName() {
            let show = false;
            if (this.nameProp) {
                let { flag } = this.nameProp;
                if (!checkBit(flag, MemberPropDef.Flag.DISABLE)) {
                    show = true;
                }
            }
            return show;
        },
        memberName() {
            let acct = this.memberInfo[MemberDef.Info.ACCT];
            let name = this.memberInfo[MemberDef.Info.NAME];
            return !this.showMemberName || name == '' || name == null ? acct : name;
        },
        isCrownVer() {
            return (
                this._ver == SiteVerDef.CROWN ||
                this._ver == SiteVerDef.OEM_CROWN ||
                this._ver == SiteVerDef.CROWN_BUY_OUT
            );
        },
        formHiddenClass() {
            return ['remind', this.hidden];
        },
        bulletinTitleClass() {
            return ['bulletin_title_v3', this.news_reminds_v3];
        },
        memberLevelAuthInfo() {
            return this.renderOptions.memberLevelAuthInfo ?? {};
        },
        clientDelMember() {
            return this.renderOptions.clientDelMember;
        },
    },
    methods: {
        logoutClick() {
            let urlSearchParams = new URLSearchParams(window.location.search);
            let returnUrl = urlSearchParams.get('returnUrl');
            let indexUrl = '/';
            profileLogoutV3(indexUrl, returnUrl);
        },
    },
};
</script>
